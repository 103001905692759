import React, { Component } from 'react';
import Loading from 'react-loading-spinner';
import ReactTooltip from 'react-tooltip';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';
import classnames from 'classnames';
import Fetch from '../../scripts/fetch.js';
import { API_BASE } from '../../scripts/constants';
import { CHART_API_BASE } from '../../scripts/constants';
import { source } from '../../scripts/constants';
import Dropdown from '../Dropdown';
import ColumnInputs from '../ChartColumnInputs';
import AlertFields from '../AlertFields';
import TagsInput from 'react-tagsinput';

import './style.css';

class MonitorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configId: props.configId || null,
            chartId: props.chartId || '',
            metricName: '',
            metricDescription: '',
            chartInputVars: [],
            signalColumn: '',
            dimensionColumns: [],
            possibleSignals: [],
            possibleDimensionColumns: [],
            rules: [
                {
                    id: 0,
                    ruleId: null,
                    filters: [['', '=', '']],
                    ruleType: 'threshold',
                    criteria: {
                        threshold: '',
                        operator: '',
                        breakEarly: true
                    },
                    isValidEmailSet: true,
                    emailNotify: [],
                    startDate: new Date(),
                    ruleSchedulePeriod: 12,
                    ruleScheduleType: 1 // hours
                }
            ],
            ruleOptionSpecifications: {},
            contributors: [],

            // loading and error states
            loadingChartInfo: false,
            errorChartInfo: true,
            chartInfoStatusMessage: '',

            loadingMetricNameCheck: false,
            errorMetricName: true,
            metricNameStatusMessage: '',

            errorSignal: false,
            signalStatusMessage: '',

            errorRuleSchedulePeriod: false,
            ruleSchedulePeriodStatusMessage: '',

            loadingChartMetricConfig: false,
            errorChartMetricConfig: false,
            errorChartMetricConfigStatusMessage: '',

            loadingRuleOptions: false,
            errorRuleOptions: false,
            errorRuleOptionsStatusMessage: '',
        };

        this.handleIdChange = this.handleIdChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleMetricNameChange = this.handleMetricNameChange.bind(this);
        this.handleMetricDescriptionChange = this.handleMetricDescriptionChange.bind(this);
        this.handleSignalColumnChange = this.handleSignalColumnChange.bind(this);
        this.handleInputVarsChanged = this.handleInputVarsChanged.bind(this);
        this.handleDimensionColumnsChange = this.handleDimensionColumnsChange.bind(this);
        this.handleRuleTypeChanged = this.handleRuleTypeChanged.bind(this);
        this.handleRuleOptionChange = this.handleRuleOptionChange.bind(this);
        this.handleRuleExtendedOptionChange = this.handleRuleExtendedOptionChange.bind(this);
        this.handleRuleEmailChange = this.handleRuleEmailChange.bind(this);
        this.handleRuleStartDateChange = this.handleRuleStartDateChange.bind(this);
        this.handleRuleScheduleTypeChange = this.handleRuleScheduleTypeChange.bind(this);
        this.handleRuleSchedulePeriodChange = this.handleRuleSchedulePeriodChange.bind(this);
        this.handleAddAlert = this.handleAddAlert.bind(this);
        this.handleRemoveAlert = this.handleRemoveAlert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoadChartData = this.handleLoadChartData.bind(this);
        this.handleContributorsChange = this.handleContributorsChange.bind(this);
        this.onFiltersChange = this.onFiltersChange.bind(this);

        this.createMonitorDescription = 'Create monitors that trigger alerts in real-time when your data meets your rule\'s requirements.';

        this.chartIdTooltipString = 'Your chart id from //charts';
        this.metricNameTooltipString = 'How you will identify this monitor';
        this.signalTooltipString = 'Signal is the primary data field that is being monitored';

        this.chartIdPlaceholder = 'ex. 6255';
        this.metricNamePlaceholder = 'ex. %CFS for People app on RS3';
        this.contributorsPlaceholder = 'Add a contributor alias (in the form of <domain>\\<alias>)';
    }

    handleContributorsChange(updatedContributors) {
        this.setState({
            contributors: updatedContributors
        });
    }

    onFiltersChange(updatedRule) {
        const rules = this.state.rules.map(rule => {
            if (rule.id === updatedRule.id) {
                return updatedRule;
            }
            return rule;
        });
        this.setState({
            rules: rules
        });
    }

    handleIdChange(event) {
        this.setState({ chartId: event.target.value });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this._fetchChartInfo(this.state.chartId);
        }
    }

    handleMetricNameChange(event) {
        const metricName = event.target.value;
        const metricNameHasValue = metricName.trim().length > 0;
        this.setState({
            metricName: metricName,
            errorMetricName: !metricNameHasValue,
            metricNameStatusMessage: !metricNameHasValue ? 'Metric name must be set and unique' : ''
        });
        if (metricNameHasValue) {
            event.persist();
        }
    }

    handleMetricDescriptionChange(event) {
        this.setState({ metricDescription: event.target.value });
    }

    handleSignalColumnChange(event) {
        const signalColumn = event.target.value;
        this.setState({
            signalColumn: signalColumn,
            dimensionColumns: this.state.dimensionColumns.filter(col => col.name !== event.target.value),
        });
        if (signalColumn && this.state.possibleSignals.includes(signalColumn)) {
            this.setState({
                errorSignal: false,
                signalStatusMessage: '',
            });
        }
        else {
            this.setState({
                errorSignal: true,
                signalStatusMessage: 'Numeric signal column must be selected'
            });
        }
    }

    handleInputVarsChanged(inputVarsArray) {
        const inputVars = inputVarsArray.map(inputVar => ({
            name: inputVar[0],
            value: inputVar[1]
        }));

        this.setState({
            chartInputVars: inputVars
        });
    }

    handleDimensionColumnsChange(newColumns) {
        this.setState({ dimensionColumns: newColumns });
    }

    handleRuleTypeChanged(ruleType, rule) {
        const defaultOptions = this._buildDefaultOptions(this.state.ruleOptionSpecifications[ruleType]);
        const updatedRule = Object.assign({}, rule, {
            ruleType: ruleType,
            criteria: defaultOptions.defaultOptions,
            extendedOptions: defaultOptions.defaultExtendedOptions
        });
        const rules = this.state.rules.map(rule => {
            if (rule.id === updatedRule.id) {
                return updatedRule;
            }
            return rule;
        });
        this.setState({
            rules: rules
        });
    }

    handleRuleOptionChange(rule, optionName, newValue) {
        const updatedRule = Object.assign({}, rule, {
            criteria: Object.assign({}, rule.criteria, {
                [optionName]: newValue
            })
        });
        const updatedRules = this.state.rules.map(oldRule =>
            updatedRule.id === oldRule.id ? updatedRule : oldRule
        );
        this.setState({
            rules: updatedRules
        });
        if (rule.extendedOptions[optionName] && rule.extendedOptions[optionName].useDimensionValue) {
            // if a dimension value has been used as an option value, add it to dimension columns
            if (_.find(this.state.dimensionColumns, (o) => { return o.name === newValue; }) > -1) {
                const newValueIndex = _.findIndex(this.state.possibleDimensionColumns, (o) => { return o.name === newValue; });
                if (newValueIndex > -1) {
                    this.setState({
                        dimensionColumns: this.state.dimensionColumns.concat(this.state.possibleDimensionColumns[newValueIndex])
                    });
                }
            }
        }
    }

    handleRuleExtendedOptionChange(rule, optionName, extendedOption, newValue) {
        // if 'useDimensionValue' option has been changed, then null out option value
        // since we are either going from using a dimension column as value to using
        // a regular value or vice versa
        let nullifiedOptionValue = rule.criteria[optionName];
        if (extendedOption === 'useDimensionValue') {
            nullifiedOptionValue = null;
            rule.extendedOptions.breakEarly = !newValue;
            rule.criteria.breakEarly = !newValue;
        }
        const updatedRule = Object.assign({}, rule, {
            criteria: Object.assign({}, rule.criteria, {
                [optionName]: nullifiedOptionValue
            }),
            extendedOptions: Object.assign({}, rule.extendedOptions, {
                [optionName]: Object.assign({}, rule.extendedOptions[optionName], {
                    [extendedOption]: newValue
                })
            })
        });
        const updatedRules = this.state.rules.map(oldRule =>
            updatedRule.id === oldRule.id ? updatedRule : oldRule
        );
        this.setState({
            rules: updatedRules
        });
    }

    handleRuleEmailChange(rule, emails) {
        const updatedRules = this.state.rules.map(oldRule => {
            if (oldRule.id === rule.id) {
                return Object.assign({}, rule, {
                    emailNotify: emails,
                    isValidEmailSet: this._isValidEmailSet(emails)
                });
            }
            return oldRule;
        });
        this.setState({
            rules: updatedRules
        });
    }

    handleRuleStartDateChange(rule, date) {
        const updatedRules = this.state.rules.map(oldRule => {
            if (oldRule.id === rule.id) {
                return Object.assign({}, rule, {
                    startDate: date
                });
            }
            return oldRule;
        });
        this.setState({
            rules: updatedRules
        });
    }

    handleRuleScheduleTypeChange(rule, scheduleType) {
        const updatedRules = this.state.rules.map(oldRule => {
            if (oldRule.id === rule.id) {
                return Object.assign({}, rule, {
                    ruleScheduleType: scheduleType
                });
            }
            return oldRule;
        });
        this.setState({
            rules: updatedRules
        });
    }

    handleRuleSchedulePeriodChange(rule, schedulePeriod) {
        // validation for rule schedule period
        // must be numeric
        this.setState({
            errorRuleSchedulePeriod: false,
            ruleSchedulePeriodStatusMessage: ''
        });
        let value = schedulePeriod;
        let hasError = false;
        try {
            value = parseInt(schedulePeriod, 10);
            hasError = isNaN(value) || value <= 0;
        }
        catch (err) {}

        if (hasError) {
            this.setState({
                errorRuleSchedulePeriod: true,
                ruleSchedulePeriodStatusMessage: 'Must be integer > 0'
            });
        }

        const updatedRules = this.state.rules.map(oldRule => {
            if (oldRule.id === rule.id) {
                return Object.assign({}, rule, {
                    ruleSchedulePeriod: !hasError ? value : schedulePeriod
                });
            }
            return oldRule;
        });

        this.setState({
            rules: updatedRules
        });
    }

    handleAddAlert() {
        const rule = {
            id: this.state.rules.slice(-1)[0].id + 1,
            ruleId: null,
            filters: [],
            ruleType: 'threshold',
            criteria: {
                threshold: '',
                operator: ''
            },
            isValidEmailSet: true,
            emailNotify: [''],
            // To avoid null or empty scheduling fields when adding a new alert, set default to start now, running every 12 hours
            ruleSchedulePeriod: 12,
            ruleScheduleType: 1,
            startDate: new Date().toUTCString(),
            extendedOptions: this._buildDefaultOptions(this.state.ruleOptionSpecifications['threshold']).defaultExtendedOptions
        };
        const updatedRules = this.state.rules.concat(rule);
        this.setState({
            rules: updatedRules
        });
    }

    handleRemoveAlert(rule) {
        const updatedRules = this.state.rules.filter(r => r.id !== rule.id);
        this.setState({
            rules: updatedRules
        });
    }

    handleSubmit(event) {
        this.setState({
            isSubmitting: true
        });
        const formObj = {
            chartId: this.state.chartId,
            metricId: this.state.metricId,
            metricName: this.state.metricName,
            inputVars: this.state.chartInputVars,
            signalColumn: this.state.signalColumn,
            configId: this.state.chartMetricPullerId,
            contributors: this.state.contributors.filter(alias => alias.trim().length > 0),
            timestampColumn: '',
            dimensionColumns: this.state.dimensionColumns.filter(col => col.name !== this.state.signalColumn),
            rules: this.state.rules,
            source: source.charts
        };
        this.props.onSubmit(formObj);
        event.preventDefault();
    }

    cleanFilters() {
        this.state.rules.forEach(rule => {
            if (!rule.filters) {
                return;
            }
            for (let i = rule.filters.length - 1; i >= 0; i--) { // each filter row
                if (!rule.filters[i][0] || !rule.filters[i][1] || !rule.filters[i][2]) { // if any of the filter's columns are empty delete
                    rule.filters.splice(i, 1);
                }
                else { // otherwise format for json
                    rule.filters[i] = {
                        Name: rule.filters[i][0],
                        Operator: rule.filters[i][1],
                        Value: rule.filters[i][2],
                    };
                }
            }
        });
    }

    handleLoadChartData() {
        this._fetchChartInfo(this.state.chartId);
    }

    componentDidMount() {
        const chartId = this.props.chartId;
        const metricId = this.props.metricId;
        if (chartId) {
            this._fetchChartInfo(chartId);
        }
        if (metricId) {
            this._fetchChartMetricPullerConfig(metricId);
        }
        this._fetchRuleOptionSpecifications();
        this._fetchRuleScheduleTypes();
    }

    _fetchChartMetricPullerConfig(metricId) {
        this.setState({
            loadingChartMetricConfig: true
        });
        Fetch(`${API_BASE}/metricPuller/puller/?metricId=${metricId}&source=${source.charts}`, {
            credentials: 'include'
        }).then(chartConfigs => {
            const chartConfig = chartConfigs[0];
            let metricName = chartConfig.MetricName;
            if (this.props.isForking) {
                metricName = '';
                chartConfig.Contributors.push(chartConfig.Author);
            }
            this.setState({
                author: chartConfig.Author,
                chartId: chartConfig.ChartId,
                metricId: chartConfig.MetricId,
                metricName: metricName,
                chartMetricPullerId: chartConfig.ChartMetricsPullerId,
                chartInputVars: chartConfig.InputVars,
                signalColumn: chartConfig.SignalColumn,
                dimensionColumns: chartConfig.DimensionColumns,
                contributors: chartConfig.Contributors,
                loadingChartMetricConfig: false,
                errorMetricName: metricName.trim().length === 0,
            });
            this._fetchChartInfo(chartConfig.ChartId);
            this._fetchRules(chartConfig.MetricId);
        }).catch(err => {
            this.setState({
                loadingChartMetricConfig: false,
                errorChartMetricConfig: true,
                errorChartMetricConfigStatusMessage: err,
            });
            // TODO: improve error display
        });
    }

    _fetchRuleOptionSpecifications() {
        this.setState({
            loadingRuleOptions: true
        });
        return Fetch(`${API_BASE}/rules/uiOptionSpecifications`, { credentials: 'include' }).then(specs => {
            const updatedRules = this.state.rules.map(rule => {
                const ruleSpec = specs[rule.ruleType];

                // build the default extended options for a rule from the spec to use in case they have not
                // already been set. use existing extendedOptions if they exist.
                const defaultOptions = this._buildDefaultOptions(ruleSpec);
                return Object.assign({}, rule, {
                    extendedOptions: Object.assign({}, defaultOptions.defaultExtendedOptions, rule.extendedOptions),
                    criteria: Object.assign({}, defaultOptions.defaultOptions, rule.criteria)
                });
            });
            this.setState({
                ruleOptionSpecifications: specs,
                rules: updatedRules
            });
        }).catch(err => {
            this.setState({
                loadingRuleOptions: false,
                errorRuleOptions: true,
                errorRuleOptionsStatusMessage: err,
            });
            // TODO: enhance error display
        });
    }

    _fetchRuleScheduleTypes() {
        return Fetch(`${API_BASE}/rules/possibleScheduleTypes`).then(scheduleTypes => {
            this.setState({
                possibleRuleScheduleTypes: scheduleTypes
            });
        });
    }

    _fetchRules(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        return Fetch(`${API_BASE}/rules?metricId=${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const rules = data.rules;
            rules.forEach(rule => {
                rule.isValidEmailSet = this._isValidEmailSet(rule.emailNotify);
                rule.filters = rule.filters.map(item => {
                    return [item.value.Name, item.value.Operator, item.value.Value];
                });
                // Some rules already have nulls for scheduling since it wasn't checked before. If they have non-null values use those, otherwise set defaults to start now, running every 12 hours.
                rule.ruleSchedulePeriod = rule.ruleSchedulePeriod || 12;
                rule.ruleScheduleType = rule.ruleScheduleType || 1;
                rule.startDate = rule.startDate || new Date().toUTCString();
            });
            this.setState({
                rules: rules.map((rule, index) => Object.assign({
                    // create id to keep track of newly-created rules
                    id: index
                },
                rule,
                {
                    criteria: Object.assign({}, this._buildDefaultOptions(this.state.ruleOptionSpecifications[rule.ruleType]).defaultOptions, rule.criteria),
                    extendedOptions: Object.assign({}, this._buildDefaultOptions(this.state.ruleOptionSpecifications[rule.ruleType]).defaultExtendedOptions, rule.extendedOptions)
                }))
            });
            if (_.isFunction(this.props.onRulesFetched)) {
                this.props.onRulesFetched(rules);
            }
        }).catch(err => {
        });
    }

    _isValidEmailSet(emails) {
        return emails.every(email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) || (emails.length === 1 && emails[0] === '');
    }

    _buildDefaultOptions(ruleSpec) {
        if (!ruleSpec) {
            return {
                defaultOptions: undefined,
                defaultExtendedOptions: undefined
            };
        }
        return ruleSpec.reduce((returnObj, spec) => {
            // create default top-level options from the given ruleOptionSpecification
            returnObj.defaultOptions[spec.OptionUIField] = spec.DefaultValue || '';

            // create default extended options on the top-level options from the given ruleOptionSpecification
            returnObj.defaultExtendedOptions[spec.OptionUIField] = spec.ExtendedOptions ? spec.ExtendedOptions.reduce((obj, extendedOption) => {
                obj[extendedOption.OptionUIField] = extendedOption.DefaultValue || '';
                return obj;
            }, {}) : undefined;
            return returnObj;
        }, {
            defaultOptions: {},
            defaultExtendedOptions: {}
        });
    }

    _fetchChartInfo(chartId) {
        this.setState({
            loadingChartInfo: true,
            errorChartInfo: false,
            chartInfoStatusMessage: ''
        });
        Promise.all([
            Fetch(`${CHART_API_BASE}/data/${chartId}`, { credentials: 'include' }),
            Fetch(`${CHART_API_BASE}/data/meta/${chartId}`, { credentials: 'include' })
        ]).then(results => {
            const data = results[0];
            const metadata = results[1];
            if (!this._isValidChart(data.cols)) {
                throw new Error('HTML charts are not currently supported');
            }
            const cols = data.cols;
            const alertingDimensions = cols.map(col => ({ name: col.name, type: col.type }));
            const possibleSignals = cols.filter(col => this._sqlServerTypeIsNumeric(col.type)).map(col => col.name);

            const temp_possibleDimensionColumns = cols.map(col => col.name.toLowerCase());
            const temp_inputVars = metadata.variables.map(inputVar => inputVar.name.toLowerCase());
            const intersection = temp_possibleDimensionColumns.filter(dim => temp_inputVars.includes(dim));

            let possibleDimensionColumns = cols.map(col => col.name).filter(col => !intersection.includes(col.toLowerCase()));
            possibleDimensionColumns = data.cols.map(col => ({ name: col.name, type: col.type })).filter(col => possibleDimensionColumns.includes(col.name));

            const updatedStateObj = {
                dimensionColumns: alertingDimensions,
                possibleDimensionColumns: possibleDimensionColumns,
                possibleSignals: possibleSignals,
                signalColumn: possibleSignals.includes(this.state.signalColumn) ? this.state.signalColumn : possibleSignals[0],
                chartInputVars: metadata.variables.map(inputVar => _.find(this.state.chartInputVars, { name: inputVar.name }) || ({ name: inputVar.name, value: inputVar.defaultValue }))
            };
            this.setState(Object.assign({
                loadingChartInfo: false
            }, updatedStateObj));
        }).catch(err => {
            this.setState({
                loadingChartInfo: false,
                errorChartInfo: true,
                chartInfoStatusMessage: `Error loading data from chart: ${err.message ? err.message : err}`
            });
        });
    }

    _isValidChart(cols) {
        return cols.some(item => this._sqlServerTypeIsNumeric(item.type));
    }

    _sqlServerTypeIsNumeric(sqlType) {
        return sqlType === 'bigint' || sqlType === 'int' || sqlType === 'float' || sqlType === 'numeric' || sqlType === 'decimal' || sqlType === 'real';
    }

    _isValidRuleCriteria(item) {
        if ((typeof item === 'string') && (!item || item.trim().length === 0)) { // undefined, null, or empty strings
            return true;
        }
        else if ((typeof item === 'object') && (!item)) { // undefined, null, or empty strings
            return true;
        }
        return false;
    }

    render() {
        const showRemoveButton = this.state.rules.length > 1 ? 'true' : null;
        const possibleDimensionColumns = this.state.possibleDimensionColumns.filter(col => col.name !== this.state.signalColumn).map(col => col.name);
        const possibleRuleScheduleTypes = this.state.possibleRuleScheduleTypes;
        const signalExists = this.state.signalColumn && this.state.possibleSignals && this.state.possibleSignals.includes(this.state.signalColumn);
        const showChartInfoDependents = !this.state.loadingChartInfo && !this.state.errorChartInfo;
        const loadingSubmit = this.props.loadingSubmit;
        const canAddNewMonitor = !this.state.rules.some(rule => {
            if (!rule.isValidEmailSet) {
                return true;
            }
            return Object.keys(rule.criteria).some(key => {
                return this._isValidRuleCriteria(rule.criteria[key]);
            });
        });
        const errorInScheduling = this.state.rules.some(rule => !rule.ruleSchedulePeriod || !rule.ruleScheduleType || !rule.startDate);
        const errorInForm = this.state.errorChartInfo || this.state.errorMetricName || !signalExists || this.state.errorRuleSchedulePeriod || !canAddNewMonitor || errorInScheduling;

        return (
            <div className="MonitorForm">
                <div className="rule-form-header">
                    <h1>
                        { this.props.title || 'Create New Data Monitor' }
                    </h1>
                    <p className="subtext">
                        {this.createMonitorDescription}
                    </p>
                </div>
                <div className="MonitorForm container-fluid" >
                    <div className="alert-section-header">
                        <h4>Configure Data Source</h4>
                        <p className="subtext">
                        </p>
                    </div>
                    <div className="form-group row">
                        <i className="tooltip-hover fa fa-info-circle" aria-hidden="true" data-tip={this.chartIdTooltipString} data-for='chart-id-tooltip'></i>
                        <label htmlFor="chartId" className="col-sm-2 control-label">Chart Id</label><ReactTooltip id='chart-id-tooltip' />
                        <div className="col-sm-4">
                            <input type="text" placeholder={this.chartIdPlaceholder} className={classnames('form-control', { 'error': this.state.errorChartInfo })} name="chartId" value={this.state.chartId} onChange={this.handleIdChange} onKeyPress={this.handleKeyPress} readOnly={this.props.chartIdReadonly}/>
                            {
                                this.state.chartInfoStatusMessage &&
                                    <div className={classnames({ 'error-status': this.state.errorChartInfo })}>
                                        {this.state.chartInfoStatusMessage}
                                    </div>
                            }
                        </div>
                        <div className="col-sm-2">
                            <button className={classnames('btn btn-default load-chart-data-btn', { 'disabled': this.state.loadingChartInfo })} onClick={() => { !this.state.loadingChartInfo && this.handleLoadChartData(); }}>
                                {
                                    this.state.loadingChartInfo ? 'Loading...' : 'Load Chart Data'
                                }
                            </button>
                        </div>
                    </div>

                    {
                        showChartInfoDependents && this.state.chartInputVars && this.state.chartInputVars.length > 0 &&
                            <div className="form-group row">
                                <label htmlFor="inputVars" className="col-sm-2 control-label">Input Variables</label>
                                <div className="col-sm-10">
                                    <div className="form-group row">
                                        <ColumnInputs
                                            numColumns={2}
                                            placeholders={['Filter Key', 'Filter Value']}
                                            readonly={[true, false]}
                                            items={this.state.chartInputVars.map(inputVar => [inputVar.name, inputVar.value])}
                                            onItemsChanged={this.handleInputVarsChanged}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                    <hr/>
                    <div className="alert-section-header">
                        <h4>Configure Metric</h4>
                        <p className="subtext">Describe what needs to be monitored.</p>
                    </div>
                    <div className="form-group row">
                        <i className="tooltip-hover fa fa-info-circle" aria-hidden="true" data-tip={this.metricNameTooltipString} data-for='metric-name-tooltip'></i>
                        <label htmlFor="metricName" className="col-sm-2 control-label">Metric Name</label><ReactTooltip id='metric-name-tooltip' />
                        <div className="col-sm-4">
                            <input type="text" placeholder={this.metricNamePlaceholder} className={classnames('form-control', { 'error': this.state.errorMetricName })} name="metricName" value={this.state.metricName} onChange={this.handleMetricNameChange}/>
                            {
                                this.state.metricNameStatusMessage &&
                                    <span className={classnames({ 'error-status': this.state.errorMetricName })}>{ this.state.metricNameStatusMessage }</span>
                            }
                        </div>
                        {
                            this.state.loadingMetricNameCheck &&
                                <div className="col-sm-2">
                                    <span>Loading...</span>
                                </div>
                        }
                    </div>
                    <div className="form-group row">
                        <i className="tooltip-hover fa fa-info-circle" aria-hidden="true" data-tip={this.signalTooltipString} data-for='signal-tooltip'></i>
                        <label htmlFor="signal" className="col-sm-2 control-label">Signal</label><ReactTooltip id='signal-tooltip' />
                        <div className="col-sm-4">
                            {
                                showChartInfoDependents ?
                                    <Dropdown
                                        className={classnames({ 'error': this.state.errorSignal })}
                                        placeholder="Select signal"
                                        possibleValues={this.state.possibleSignals.map(signal => ({ ID: signal, Name: signal }))}
                                        selectedValue={this.state.signalColumn}
                                        onChange={this.handleSignalColumnChange}
                                    />
                                    : <p>Chart info must be loaded first.</p>
                            }
                            {
                                showChartInfoDependents && this.state.errorSignal &&
                                    <span className={classnames({ 'error-status': this.state.errorSignal })}>{ this.state.signalStatusMessage }</span>
                            }
                        </div>
                    </div>

                    <hr/>

                    <div className="alert-section-header">
                        <h4>Add Monitors</h4>
                        <p className="subtext">Configure the monitors which will run at a regular interval on the configured metric. The default is to run every 12 hours.</p>
                    </div>
                    {this.state.rules.map((rule, index) =>
                        <div key={rule.ruleId || `rule-${index}`} className="form-group ColumnInputs remove-nested-padding">
                            <AlertFields
                                rule={rule}
                                dimensionColumns={possibleDimensionColumns}
                                possibleRuleScheduleTypes={possibleRuleScheduleTypes}
                                ruleOptionSpecification={this.state.ruleOptionSpecifications[rule.ruleType]}
                                errorRuleSchedulePeriod={this.state.errorRuleSchedulePeriod}
                                ruleSchedulePeriodStatusMessage={this.state.ruleSchedulePeriodStatusMessage}
                                showChartInfoDependents={showChartInfoDependents}
                                onRuleTypeChange={ruleType => this.handleRuleTypeChanged(ruleType, rule)}
                                onRuleOptionChange={(option, value) => this.handleRuleOptionChange(rule, option, value)}
                                onRuleExtendedOptionsChange={(option, extendedOption, value) => this.handleRuleExtendedOptionChange(rule, option, extendedOption, value)}
                                onRuleEmailChange={(emails) => this.handleRuleEmailChange(rule, emails)}
                                onRuleStartDateChange={(date) => this.handleRuleStartDateChange(rule, date)}
                                onRuleScheduleTypeChange={(scheduleType) => this.handleRuleScheduleTypeChange(rule, scheduleType)}
                                onRuleSchedulePeriodChange={(schedulePeriod) => this.handleRuleSchedulePeriodChange(rule, schedulePeriod)}
                                onFiltersChange={(filters) => this.onFiltersChange(rule)}
                            />
                            { showRemoveButton &&
                            <div>
                                <div className="remove-row" onClick={() => this.handleRemoveAlert(rule)}>
                                    <div className="remove-button">
                                    &times;
                                    </div>
                                    <span className="remove-button-text">
                                    Remove This Monitor
                                    </span>
                                </div>
                                <hr />
                            </div>
                            }
                        </div>
                    )}
                    {false &&
                        <div className="row add-button-container">
                            <div className={'add-button col-sm-3 ' + (!canAddNewMonitor ? 'fade-add' : '')} onClick={this.handleAddAlert}>
                                <div className="add-button-icon">
                                    &#xECC8;
                                </div>
                                <span className="add-button-text">
                                Add Another Monitor
                                </span>
                            </div>
                        </div>
                    }
                    <hr />
                    <div className="alert-section-header">
                        <h4>Contributors</h4>
                    </div>
                    <TagsInput
                        value={this.state.contributors}
                        onChange={this.handleContributorsChange}
                        addKeys={[9, 13, 32, 186, 188]} // Tab, Enter, Space, Semicolon, Comma
                        addOnBlur={true}
                        inputProps={ { className: 'react-tagsinput-input', placeholder: this.contributorsPlaceholder } }
                    />
                    { this.state.author && <p className={'author-desc'}><b>Author:</b> {this.state.author}</p> }
                    <Loading
                        isLoading={loadingSubmit}
                        loadingClassName='loading'
                    >
                        <button disabled={loadingSubmit || errorInForm} className={classnames('btn btn-default', { 'error': errorInForm })} onClick={this.handleSubmit}>Submit</button>
                    </Loading>
                </div>
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default MonitorForm;
