import React, { PureComponent } from 'react';

import './style.css';

export default class CriteriaItem extends PureComponent {
    render() {
        if (this.props.option) {
            return (
                <tr className="CriteriaItem" align={'left'}>
                    <td>{this.toTitleCase(this.props.option)}</td>
                    <td>{`${this.props.value}`}</td>
                </tr>
            );
        } else {
            return (
                <tr className="CriteriaItem" align={'left'}>
                    <td>{'Signal'}</td>
                    <td>{this.props.signal}</td>
                </tr>
            );
        }
    }

    toTitleCase(str) {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}