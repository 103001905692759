import React, { Component } from 'react';
import _ from 'lodash';
import Loading from 'react-loading-spinner';
import classnames from 'classnames';
import Fetch from '../../scripts/fetch.js';
import { MetricName } from '../../scripts/constants.js';
import { API_BASE } from '../../scripts/constants';
import Filters from './Filters';
import HistoryTable from './HistoryTable';
import RuleTypes from './RuleTypes';
import EmbeddedChart from '../utilities/EmbeddedChart';
import Select from 'react-select';

import './style.css';

class ViewAeroRule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rule: {
                value: props.match.params.RuleId,
            },
            loadingRules: false,
            errorRules: false,
            rulesStatusMessage: '',
            loadingAlerts: false,
            errorAlerts: false,
            alertsStatusMessage: '',
            renderRules: false,
            renderAlerts: false,
            metric: {
                value: '',
                link: '',
            },
            filters: [],
            criteria: {
                name: '',
                threshold: '',
                operator: '',
                extendedOptions: {},
            },
            signal: '',
            ruleRuns: {
                ruleId: props.match.params.RuleId,
                alerts: [],
            },
        };

        this.ruleTypeToString = {
            'threshold': 'Threshold',
            'appVersionTimeSeries': 'App Version Comparison',
            'appVersionCompare': 'App Version Comparison (Time Series)'
        };

        this.ruleTypeDescription = {
            'threshold': 'My metric misses the flight targets',
            'appVersionTimeSeries': 'My metric regresses from previous app versions',
            'appVersionCompare': 'My metric regresses from previous app versions over the same period of time after release'
        };

        this.hiddenDimensions = new Set(['AppName', 'MetricName', 'Platform', 'CurrentBucket']);
        // TODO: Include threshold+operator and join together (like some charts have a column as '>= 95')?
        this.hiddenCriterion = new Set(['operator', 'threshold', 'breakearly']);
        this.handleDimensionChanged = this.handleDimensionChanged.bind(this);
    }

    componentDidMount() {
        const MetricId = this.props.match.params.MetricId;
        if (MetricId) {
            this._fetchRule(MetricId);
            this._fetchAlertsForRule(MetricId);
        }
    }

    _fetchDimensions(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        this.setState({
            loadingDimensions: true,
            errorDimensions: false,
            dimensionsStatusMessage: ''
        });
        return Fetch(`${API_BASE}/metrics/possibleDimensions/${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const dimensions = data.Dimensions.filter(dim => !this.hiddenDimensions.has(dim.name)).map(dimension => {
                return ({
                    value: dimension.name,
                    label: dimension.name
                });
            });
            const variables = this._updateVariables(dimensions);
            this.setState({
                dimensions: dimensions,
                selectedDimensions: dimensions,
                loadingDimensions: false,
                errorDimensions: false,
                dimensionsStatusMessage: '',
                variables: variables
            });
        }).catch(err => {
            this.setState({
                loadingDimensions: false,
                errorDimensions: true,
                dimensionsStatusMessage: err,
            });
        });
    }

    _updateVariables(dimensions) {
        const dimensionVariable = dimensions.map(dim => dim.value).join(',');
        return this.state.variables.map(item => {
            if (item.name === 'Dimensions') item.value = dimensionVariable;
            return item;
        });
    }

    handleDimensionChanged(values) {
        const variables = this._updateVariables(values);
        this.setState({
            selectedDimensions: values,
            variables: variables
        });
    }

    _fetchRule(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        this.setState({
            loadingRules: true,
            errorRules: false,
            rulesStatusMessage: ''
        });
        return Fetch(`${API_BASE}/rules?metricId=${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const rules = data.rules;

            // Get unique rule types
            let ruleTypes = _.uniqWith(rules.map(rule => rule.ruleType)).sort();

            // Get unique rule criteria
            let criteria = {};
            rules.map(rule => rule.criteria)
                .forEach(criteriaSet => {
                    Object.keys(criteriaSet)
                        .forEach(key => {
                            if (!this.hiddenCriterion.has(key.toLowerCase()) && !criteria[key]) {
                                criteria[this._formaCriteriaKey(key)] = criteriaSet[key];
                            }
                        });
                });

            // Get unique filters (aka. App configs)
            let filters = _.uniq(rules.map(rule => {
                const obj = {};
                rule.filters.forEach(item => {
                    obj[item.name] = item.value;
                });
                return obj;
            }));
            const filterComparator = (obj1, obj2) => {
                const keys1 = Object.keys(obj1);
                const keys2 = Object.keys(obj2);
                // Must have same keys
                if (!_.isEmpty(_.xor(keys1, keys2))) {
                    return false;
                }
                // And every k-v must match
                return keys1.every(key => obj1[key] === obj2[key]);
            };
            filters = _.uniqWith(filters, filterComparator);

            let uniqueAppVersions = new Set();
            let uniqueBuckets = new Set();
            let uniqueOSRings = new Set();
            filters.forEach(filter => {
                uniqueAppVersions.add(filter.AppVersion);
                uniqueBuckets.add(filter.CurrentBucket);
                uniqueOSRings.add(filter.OS);
            });
            uniqueAppVersions = [...uniqueAppVersions];
            uniqueBuckets = [...uniqueBuckets];
            uniqueOSRings = [...uniqueOSRings];

            this.setState({
                ruleId: rules.ruleId,
                loadingRules: false,
                renderRules: true,
                monitorName: rules[0].metricName,
                filters: filters,
                appName: filters[0].AppName,
                platform: filters[0].Platform,
                uniqueAppVersions: uniqueAppVersions,
                uniqueBuckets: uniqueBuckets,
                uniqueOSRings: uniqueOSRings,
                signal: rules[0].signal,
                ruleTypes: ruleTypes,
                criteria: criteria,
                variables:
                [
                    {
                        'name': 'MetricId',
                        'value': metricId
                    },
                    {
                        'name': 'Lookback',
                        'value': 28
                    },
                    {
                        'name': 'Dimensions',
                        'value': ''
                    }
                ]
            }, () => this._fetchDimensions(metricId));
        }).catch(err => {
            this.setState({
                loadingRules: false,
                errorRules: true,
                rulesStatusMessage: err,
            });
        });
    }

    _formaCriteriaKey(key) {
        switch (key) {
        case 'PreviousVersions':
        case 'AppVersions': key = 'Previous App Versions'; break;
        default: break;
        }
        return key// insert a space before all caps
            .replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, (str) => str.toUpperCase()).trim();
    }

    _fetchAlertsForRule(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        this.setState({
            loadingAlerts: true,
            errorAlerts: false,
            alertsStatusMessage: ''
        });
        return Fetch(`${API_BASE}/alerts/${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const alerts = data;
            var alertItems = [];

            if (alerts) {
                alertItems = alerts.map((item) => this.formatAlert(item));
            }

            this.setState({
                loadingAlerts: false,
                renderAlerts: true,
                ruleRuns: {
                    alerts: alertItems,
                },
                loadingRules: false,
            });
        }).catch(err => {
            this.setState({
                loadingAlerts: false,
                errorAlerts: true,
                alertsStatusMessage: err,
            });
        });
    }

    formatAlert(rawAlert) {
        var payload = JSON.parse(rawAlert.AlertPayload);
        const alertObj = {
            AlertRecordId: rawAlert.AlertRecordId,
            CreatedAt: rawAlert.CreatedAt,
            MetricValue: payload.triggeringValue,
            IsNew: rawAlert.IsNew,
        };
        delete payload.alertCriteria;
        delete payload.filters;
        delete payload.triggeringValue;
        alertObj.extendedOptions = JSON.stringify(payload);
        return alertObj;
    }

    _metricNameToId(metricName) {
        switch (metricName) {
        case MetricName.ActiveDeviceCount: return 1;
        case MetricName.EngagementTime: return 2;
        case MetricName.AccessibilityUsage: return 3;
        case MetricName.CrashFreeDevices: return 4;
        case MetricName.CrashFreeSessionsFG: return 5;
        case MetricName.CrashFreeSessionsBG: return 6;
        case MetricName.Performance: return 7;
        case MetricName.NetStarRating: return 8;
        case MetricName.UIFSubmitted: return 9;
        default: return 0;
        }
    }

    _metricNameToTargetValue(metricName) {
        switch (metricName) {
        case MetricName.ActiveDeviceCount: return 100;
        case MetricName.EngagementTime: return 0;
        case MetricName.AccessibilityUsage: return 0;
        case MetricName.CrashFreeDevices: return 95;
        case MetricName.CrashFreeSessionsFG: return 99;
        case MetricName.CrashFreeSessionsBG: return 99;
        case MetricName.Performance: return 3500;
        case MetricName.NetStarRating: return 0;
        case MetricName.UIFSubmitted: return 0;
        default: return 0;
        }
    }

    _metricNameToMaxValue(metricName) {
        switch (metricName) {
        case MetricName.ActiveDeviceCount: return '';
        case MetricName.EngagementTime: return '';
        case MetricName.AccessibilityUsage: return '';
        case MetricName.CrashFreeDevices: return 100;
        case MetricName.CrashFreeSessionsFG: return 100;
        case MetricName.CrashFreeSessionsBG: return 100;
        case MetricName.Performance: return '';
        case MetricName.NetStarRating: return '';
        case MetricName.UIFSubmitted: return '';
        default: return '';
        }
    }

    render() {
        const renderContent = !this.state.loadingRules && !this.state.loadingAlerts && this.state.renderRules && this.state.renderAlerts && !this.state.errorRules && !this.state.errorAlerts;
        var loadingBoxClasses = classnames(
            'status-container',
            {
                'alert alert-danger': this.state.errorRules || this.state.errorRules
            }
        );
        const boardUrl = encodeURI(`http://boards/#/IANMetricHistoryForNEXTMonitors?release=All&appVersion=All&appName=${this.state.appName}&metricId=${this._metricNameToId(this.state.signal)}&metricName=${this.state.signal}&targetValue=${this._metricNameToTargetValue(this.state.signal)}&maxValue=${this._metricNameToMaxValue(this.state.signal)}`);
        return (
            <div className="ViewAeroRule main">
                <div className="rule-form-header">
                    <h1>
                        {this.state.monitorName}
                    </h1>
                    <p className="subtext">
                        View the set rules for this monitor and view previous alerts that have fired.
                    </p>
                </div>

                { renderContent &&
                    <div className="container-fluid" >
                        <br />
                        <div className="form-group row">
                            <h4>App configurations being monitored</h4>
                            <Filters
                                appName={this.state.appName}
                                signal={this.state.signal}
                                platform={this.state.platform}
                                appVersions={this.state.uniqueAppVersions}
                                buckets={this.state.uniqueBuckets}
                                OSReleases={this.state.uniqueOSRings}
                            />
                        </div>
                        <br />
                        <div className="form-group row">
                            <h4>Rules being used to monitor</h4>
                            <RuleTypes
                                ruleTypes={this.state.ruleTypes}
                                ruleTypeToString={this.ruleTypeToString}
                                ruleTypeDescription={this.ruleTypeDescription}
                            />
                        </div>
                        <br />
                        { this.state.variables && _.findIndex(this.state.variables, (o) => { return o.Name === 'Dimensions'; }) &&
                            <EmbeddedChart
                                id={'history-chart'}
                                chartId={50863}
                                variables={this.state.variables}
                                className={'embedded-chart'}
                            />
                        }
                        { !this.state.loadingDimensions && !this.state.errorDimensions && this.state.dimensions &&
                            <div className={'form-group row'}>
                                <label htmlFor={'dimensions-select'} className={'col-sm-2 control-label'}>Dimensions</label>
                                <Select
                                    name={'dimensions-select'}
                                    placeholder={'Select Dimensions'}
                                    className={'col-sm-10'}
                                    multi={true}
                                    closeOnSelect={false}
                                    value={this.state.selectedDimensions}
                                    onChange={this.handleDimensionChanged}
                                    options={this.state.dimensions}
                                />
                            </div>
                        }
                        {
                            this.state.dimensionsStatusMessage
                        }
                        <div className={'form-group row'}>
                            <iframe
                                className={'history-board'}
                                title={'history-board'}
                                scrolling={'no'}
                                src={boardUrl}
                            />
                        </div>
                        { false &&
                            <div className="form-group row">
                                <h4>Alert History</h4>
                                <HistoryTable alerts={this.state.ruleRuns.alerts} />
                            </div>
                        }
                    </div>
                }
                <Loading
                    isLoading={!renderContent}
                    loadingClassName={'loading'}
                >
                    <div className={loadingBoxClasses}>
                        {
                            this.state.rulesStatusMessage &&
                            <div className={classnames({ 'error-status': this.state.errorRules })}>{ this.state.rulesStatusMessage }</div>
                        }
                        {
                            this.state.alertsStatusMessage &&
                            <div className={classnames({ 'error-status': this.state.errorRules })}>{ this.state.alertsStatusMessage }</div>
                        }
                    </div>
                </Loading>
            </div>
        );
    }
}

export default ViewAeroRule;
