import React, { PureComponent } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import './style.css';

export default class AlertItem extends PureComponent {
    render() {
        const dimensions = this._formatDimensions(JSON.parse(this.props.options).Dimensions);
        let triggeringValueDisplay = this._formatTriggeringValue(this.props.value);
        return (
            <tr className={classnames('AlertItem', { 'new': this.props.isNew })} align={'left'}>
                <td>
                    <span>{moment(this.props.date).format('MMM DD, YYYY h:mm a')}</span>
                </td>
                <td>{triggeringValueDisplay}</td>
                <td>{dimensions.map(i => i)}</td>
            </tr>
        );
    }

    _formatTriggeringValue(value) {
        if (Math.round(value) !== value) { // Not an integer
            if (typeof value === 'object') {
                value = <div>{value.NewMetric.toFixed(2)} <span className={'vs'}>(vs. {value.OldMetric.toFixed(2)})</span></div>;
            }
            else {
                value = value.toFixed(2);
            }
        }
        return value;
    }

    _formatDimensions(obj) {
        const newObj = Object.keys(obj).map(key => {
            if (typeof obj[key] === 'object') {
                return <div><b>{key}</b>: {this._formatDimensionValue(obj[key].NewMetric)} <span className={'vs'}>(vs. {this._formatDimensionValue(obj[key].OldMetric)})</span></div>;
            }
            else {
                return <div><b>{key}</b>: {obj[key]}</div>;
            }
        });
        return newObj;
    }

    _formatDimensionValue(val) {
        if (parseFloat(val) == val && Math.round(val) !== val) { // is a number and not an integer
            return val.toFixed(2);
        }
        else if (parseFloat(val) == val && Math.round(val) === val) { // is a number and is an integer
            return this._formatUIFriendlyNumber(val);
        }
        else {
            return val;
        }
    }

    _formatUIFriendlyNumber(num) {
        const sign = Math.sign(num) === -1 ? '-' : '';
        num = Math.abs(num);
        if (num >= 1000000000) {
            return sign + (num / 1000000000).toFixed(2) + 'B';
        }
        if (num >= 1000000) {
            return sign + (num / 1000000).toFixed(2) + 'M';
        }
        if (num >= 10000) {
            return sign + (num / 1000).toFixed(2) + 'K';
        }
        return num;
    }
}