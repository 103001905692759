import React, { Component } from 'react';
import PageMenu from '../PageMenu';
import Main from '../Main';
import Footer from '../Footer';

import './style.css';

/**
 * Insipinia bootstrap theme integration
 *
 * Note that insipinia static files are kept in src/ directory
 * directory by design. When importing assets in react code, webpack
 * can minify and pack the files into the production bundle, preventing
 * us from having to serve the static files to users ourselves
 */
import '../../insipinia/css/bootstrap.min.css';
import '../../insipinia/css/animate.css';
import '../../insipinia/css/style.css';
import '../../insipinia/font-awesome/css/font-awesome.css';

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="page-content gray-bg">
                    <PageMenu />
                    <Main />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default App;
