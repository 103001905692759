import React, { PureComponent } from 'react';

export default class Footer extends PureComponent {
    render() {
        return (
            <div className={'Footer container gray-bg'}>
                <div className={'row'}>
                    <div className={'col-lg-8 col-lg-offset-2 text-center m-t-lg m-b-lg'}>
                        <p><strong>Microsoft Confidential – Internal Use Only. &copy; 2018</strong></p>
                        <p><strong><a href='https://microsoft.sharepoint.com/teams/msdpn/sitepages/default.aspx'>Microsoft Data Protection Notice </a></strong></p>
                    </div>
                </div>
            </div>
        );
    }
}