import React, { PureComponent } from 'react';
import AlertInputs from '../AlertInputs';
import './style.css';

export default class AlertFields extends PureComponent {
    constructor(props) {
        super(props);

        this.handleAddNew = this.handleAddNew.bind(this);
    }

    handleInputChange(rowIdx, colIdx, newValue) {
        if (!this.props.readonly) {
            const items = this.props.items.slice();
            items[rowIdx][colIdx] = newValue;
            this.props.onItemsChanged(items);
        }
    }

    handleAddNew() {
        this.props.handleAddAlert(this.props.rules);
    }

    handleRemoveRow(rowIdx) {
        const items = this.props.items.slice();
        items.splice(rowIdx, 1);
        this.props.onItemsChanged(items);
    }

    render() {
        return (
            <div className="ColumnInputs">
                {
                    this.props.ruleOptionSpecification && this.props.rule &&
                        <AlertInputs
                            rule={this.props.rule}
                            showChartInfoDependents={this.props.showChartInfoDependents}
                            dimensionColumns={this.props.dimensionColumns}
                            ruleOptionSpecification={this.props.ruleOptionSpecification}
                            possibleRuleScheduleTypes={this.props.possibleRuleScheduleTypes}
                            errorRuleSchedulePeriod={this.props.errorRuleSchedulePeriod}
                            ruleSchedulePeriodStatusMessage={this.props.ruleSchedulePeriodStatusMessage}
                            onRuleTypeChange={this.props.onRuleTypeChange}
                            onRuleOptionChange={this.props.onRuleOptionChange}
                            onRuleExtendedOptionsChange={this.props.onRuleExtendedOptionsChange}
                            onRuleEmailChange={this.props.onRuleEmailChange}
                            onRuleStartDateChange={this.props.onRuleStartDateChange}
                            onRuleScheduleTypeChange={this.props.onRuleScheduleTypeChange}
                            onRuleSchedulePeriodChange={this.props.onRuleSchedulePeriodChange}
                            onFiltersChange={this.props.onFiltersChange}
                        />
                }
            </div>
        );
    }
}