import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';
import Fetch from '../../scripts/fetch.js';
import { API_BASE } from '../../scripts/constants';
import { source } from '../../scripts/constants';
import MonitorForm from '../MonitorForm';

import './style.css';

class ForkMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metricId: parseInt(this.props.match.params.MetricId, 10) || 0,
            rules: [], // written to by handleRulesFetched. basically acts as snapshot of existing rules configuration
            ruleOptionSpecifications: {},
            errorStatus: ''
        };

        this.handleRulesFetched = this.handleRulesFetched.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.notificationSystem = null;
        this.notificationStyle = {
            'notification-success:before': {
                content: '',
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ createdMetric: null });
        }
    }

    handleSubmit(formObj) {
        this._submitForm(formObj);
    }

    handleRulesFetched(rules) {
        this.setState({
            rules: rules
        });
    }

    _submitForm(formObj) {
        this.setState({
            loadingSubmit: true
        });
        formObj = Object.assign(formObj, { chartConfigId: null, metricId: null, source: source.charts });
        Fetch(`${API_BASE}/metricPuller/setup`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_.omit(formObj, 'rules'))
        }).then((metricId) => {
            formObj.rules.forEach((rule) => {
                rule.ruleId = null;
                Fetch(`${API_BASE}/rules`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(Object.assign({}, { source: source.charts }, rule, { metricId }, { metricName: formObj.metricName }))
                }).then(ruleId => {
                    this.setState({
                        loadingSubmit: false,
                    });
                    this.props.history.push('/monitor/list', { createdMetric: formObj.metricName });
                });
            });
        }).catch(err => {
            this.setState({
                loadingSubmit: false,
                errorStatus: err.message
            });
            this.addNotification('Error', 'Chart Configuration Could not be submitted: ' + err.message, 'error');
        });
    }

    componentDidMount() {
        this.notificationSystem = this.refs.notificationSystem;
    }

    addNotification(title, msg, lvl) {
        this.notificationSystem.addNotification({
            title: title,
            message: msg,
            level: lvl,
            autoDismiss: 3,
        });
    }

    render() {
        return (
            <div className="ForkMonitor">
                <MonitorForm
                    title={'Fork Monitors'}
                    metricId={this.state.metricId}
                    chartIdReadonly={true}
                    onRulesFetched={this.handleRulesFetched}
                    onSubmit={this.handleSubmit}
                    loadingSubmit={this.state.loadingSubmit}
                    isForking={true}
                />
                {
                    this.state.errorStatus && <span className="error-status">{this.state.errorStatus}</span>
                }
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default ForkMonitor;