import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './style.css';

export default class PageMenu extends Component {
    render() {
        return (
            <div className={ classnames('PageMenu', 'container-fluid', 'light-grey-bg') }>
                <nav className="navbar navbar-static-top">
                    <ul className="nav navbar-top-links navbar-left">
                        { /* <span className="banner-icon"><a href="/"><img src="/home/img/lighthouse_icon_30X30.png" alt="logo" /></a></span> */ }
                        <li>
                            <Link to='/'>Home</Link>
                        </li>
                        <li>
                            <Link to='/getstarted'>Getting Started</Link>
                        </li>
                        <li>
                            <Link to='/monitor/new'>Create Data Monitor</Link>
                        </li>
                        <li>
                            <Link to='/monitor/list'>My Data Monitors</Link>
                        </li>
                    </ul>
                    <ul className="nav navbar-top-links navbar-right">
                        <li>
                            <a href="/next/new">Monitor Aero Apps</a>
                        </li>
                        <li>
                            <a href="mailto:ian_support@microsoft.com">Contact Us</a>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}