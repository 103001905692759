import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './style.css';

export default class RuleItem extends PureComponent {

    _formatRule(rule) {
        return `Rule ${rule.ruleId}: ${rule.filters && rule.filters.map(filter => `"${filter.name}":"${filter.value}"`).join(' | ')}`;
    }

    render() {
        const rule = this.props.rule;
        return (
            <div className="RuleItem">
                <Link to={{ pathname: `/rule/edit/${rule.ruleId}` }}>
                    <div className="ruleHeader">
                    {this._formatRule(rule)}
                    </div>
                    <div className="ruleSubtitle">
                        Created by {`${rule.author || 'system'}`}...
                    </div>
                </Link>
            </div>
        )
    }
}