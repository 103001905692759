import React, { Component } from 'react';
import RuleList from './RuleList';

import './style.css';

export default class RuleListPage extends Component {
    render() {
        return (
            <div className="RuleListPage">
                <h1>View Your Rules</h1>
                <RuleList paginationSize={20} />
            </div>
        )
    }
}