const { adalApiFetch } = require('./adalConfig');

/**
 * Helper wrapper around base fetch function to capture error response
 * and include common headers.
 *
 * @param {string} url
 * @param {Object} options
 */
// TODO: If we need to support IE then this should be replaced with a polyfill
export default function Fetch(url, options) {
    let fullOptions = Object.assign({
        // include credentials with requests for windows auth
        credentials: 'include',
        mode: 'cors'
    }, options);

    let errorResponse = null;

    return adalApiFetch(fetch, url, fullOptions).then((response) => {
        if (response.ok) {
            return response;
        }
        else if (response.headers &&
                 response.headers.get('Content-Type') &&
                 response.headers.get('Content-Type').startsWith('application/json')) {
            errorResponse = response;
            return response;
        }
        else {
            var error = new Error(response.statusText);
            error.response = response;
            console.log('Fetch Request Failed: ' + url, error);
            throw error;
        }
    }).then((response) => {
        return response.json().catch(err => {
            return response; // don't throw error when response is not json, just return response as is
        });
    }).then((json) => {
        if (errorResponse) {
            var error = new Error(json.message);
            error.response = errorResponse;
            console.log('Fetch Request Failed JSON: ' + url, error);
            throw error;
        }
        return json;
    });
}