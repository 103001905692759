import React, { PureComponent } from 'react';
import CriteriaItem from './CriteriaItem';

import './style.css';

export default class CriteriaOptions extends PureComponent {
    render() {
        let options = [];
        let keys = [];

        if (this.props.criteria.baseOptions !== null && this.props.criteria.baseOptions !== undefined) {
            keys = Object.keys(this.props.criteria.baseOptions).filter(key => key.toLowerCase() !== 'breakearly');
        }
        options.push(<CriteriaItem signal={this.props.signal} key={-1}/>);
        for (var i = 0; i < keys.length; i++) {
            options.push(<CriteriaItem option={keys[i]} value={this.props.criteria.baseOptions[keys[i]]} key={keys[i]}/>);
        }

        return (
            <table className="CriteriaItem table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {options.length > 0 && options}
                </tbody>
            </table>
        );
    }
}