import React, { Component } from 'react';
import AlertItem from './AlertItem';
import ReactPaginate from 'react-paginate';

import './style.css';

export default class HistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            pageCount: 1,
            selectedPage: 0,
            offset: 0
        };

        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick(data) {
        let selectedPage = data.selected;
        let offset = Math.ceil(selectedPage * this.state.rowsPerPage);
        this.setState({ selectedPage, offset });
    }

    componentDidMount() {
        let totalRows = this.props.alerts.length;
        let pageCount = totalRows / this.state.rowsPerPage;
        this.setState({ pageCount });
    }

    render() {
        let alerts = this.props.alerts.slice(this.state.offset, this.state.offset + this.state.rowsPerPage);

        var alertItems = [];
        if (this.props && alerts !== undefined && alerts.length > 0) {
            alertItems = alerts.map(item =>
                <AlertItem
                    key={item.AlertRecordId}
                    isNew={item.IsNew}
                    date={item.CreatedAt}
                    value={item.MetricValue}
                    options={item.extendedOptions}
                />);
        }
        else {
            alertItems = [<tr key='empty'><td>No alerts to show</td><td /><td /></tr>];
        }

        return (
            <div className="HistoryTable">
                <table className="table table-striped">
                    <thead>
                        <tr align="left">
                            <th>Date</th><th>Triggering Value</th><th>Extended Options</th>
                        </tr>
                    </thead>
                    {alertItems !== undefined &&
                        <tbody>
                            {alertItems}
                        </tbody>
                    }
                </table>
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={<a href="">...</a>}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </div>
        );
    }
}