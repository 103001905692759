import React, { PureComponent } from 'react';
import FilterItem from './FilterItem';

import './style.css';

export default class Filters extends PureComponent {
    render() {
        if (this.props.value !== null && this.props.value !== undefined) {
            const filterItems = this.props.value.map((item) => <FilterItem key={item} value={item} />);
            if (filterItems !== null && filterItems !== 'undefined' && filterItems.length > 0) {
                return <ul className="Filters list-inline filter-span">{filterItems}</ul>;
            }
        }
        return <ul className="Filters list-inline filter-span"><li className="inline-padding">No filters added</li></ul>;
    }
}
