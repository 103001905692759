import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateRule from '../CreateRule';
import RuleListPage from '../RuleListPage';
import MonitorListPage from '../MonitorListPage';
import GettingStarted from '../GettingStarted';
/* Base monitor routes */
import CreateMonitor from '../CreateMonitor';
import EditMonitor from '../EditMonitor';
import ForkMonitor from '../ForkMonitor';
import ViewRule from '../ViewRule';
/* Aero App monitor routes */
import CreateAeroMonitor from '../CreateAeroMonitor';
import EditAeroMonitor from '../EditAeroMonitor';
import ForkAeroMonitor from '../ForkAeroMonitor';
import ViewAeroRule from '../ViewAeroRule';

import './style.css';

const ChartRoutes = () => (
    <Switch>
        <Route path='/monitor/new' component={CreateMonitor} />
        <Route path='/monitor/edit/:MetricId' component={EditMonitor} />
        <Route path='/monitor/fork/:MetricId' component={ForkMonitor} />
        <Route path='/monitor/view/:MetricId' component={ViewRule} />
    </Switch>
);

const AeroRoutes = () => (
    <Switch>
        <Route path='/next/new' component={CreateAeroMonitor} />
        <Route path='/next/edit/:MetricId' component={EditAeroMonitor} />
        <Route path='/next/fork/:MetricId' component={ForkAeroMonitor} />
        <Route path='/next/view/:MetricId' component={ViewAeroRule} />
    </Switch>
);

const RuleRoutes = () => (
    <Switch>
        <Route path='/rule/new' component={CreateRule} />
        <Route path='/rule/edit/:ruleId' component={CreateRule} />
        <Route path='/rule/list' component={RuleListPage} />
    </Switch>
);

export default function Main(Props) {
    return (
        <main className="Main wrapper-content">
            <div className="inner-main white-bg">
                <Switch>
                    <Route exact path='/' component={() => window.location = '/home'} />
                    <Route exact path='/monitor/list' component={MonitorListPage} />
                    <Route path='/monitor' component={ChartRoutes} />
                    <Route path='/rule' component={RuleRoutes} />
                    <Route path='/getstarted' component={GettingStarted} />
                    <Route path='/next' component={AeroRoutes} />
                </Switch>
            </div>
        </main>
    );
}