module.exports = {
    API_BASE: '/api/v1',
    CHART_API_BASE: 'https://charts.ms',
    AUTHORIZED_USERS: ['redmond\\manasiat', 'redmond\\mumuneer', 'redmond\\remykris'],
    RULE_TYPES: {
        1: 'threshold',
        2: 'nDayThreshold'
    },
    RULE_OPTION_TYPES: {
        Number: 1,
        String: 2,
        Boolean: 3,
        Set: 4
    },
    source: {
        'charts': 1,
        'aero': 2,
    },
    MetricName: {
        ActiveDeviceCount: 'Active Device Count',
        EngagementTime: 'Engagement Time (Seconds)',
        AccessibilityUsage: 'Accessibility Usage (Hours)',
        CrashFreeDevices: 'Crash-free Device %',
        CrashFreeSessionsFG: 'Crash-free Foreground Sessions %',
        CrashFreeSessionsBG: 'Crash-free Background Sessions %',
        Performance: 'Performance (Milliseconds)',
        NetStarRating: 'Net Star Rating',
        UIFSubmitted: 'UIF Submitted'
    }
};