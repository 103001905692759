import React, { PureComponent } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import './style.css';

export default class AlertItem extends PureComponent {
    render() {
        const dimensions = JSON.parse(this.props.options).Dimensions;
        let dimArray = [];
        Object.keys(dimensions).filter( (key) => {
            dimArray.push(`${key}: ${dimensions[key]}`);
            return -1;
        });
        const dimString = dimArray.join(', ');
        let triggeringValueDisplay = this.props.value;
        if (Math.round(triggeringValueDisplay) !== triggeringValueDisplay) {
            triggeringValueDisplay = triggeringValueDisplay.toFixed(2);
        }
        return (
            <tr className={classnames('AlertItem', { 'new': this.props.isNew })} align={'left'}>
                <td>
                    <span>{moment(this.props.date).format('MMM DD, YYYY h:mm a')}</span>
                </td>
                <td>{triggeringValueDisplay}</td>
                <td>{dimString}</td>
            </tr>
        );
    }
}