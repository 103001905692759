import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';
import Fetch from '../../scripts/fetch.js';
import { API_BASE } from '../../scripts/constants';
import { source } from '../../scripts/constants';
import MonitorForm from '../MonitorForm';

import './style.css';

class CreateMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSubmit: false,
            errorStatus: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        this.notificationStyle = {
            'notification-success:before': {
                content: '',
            }
        };
    }

    handleSubmit(formObj) {
        this._submitForm(formObj);
    }

    _submitForm(formObj) {
        this.setState({
            loadingSubmit: true
        });
        Fetch(`${API_BASE}/metricPuller/setup`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.assign(_.omit(formObj, 'rules'), { source: source.charts }))
        }).then((metricId) => {
            formObj.rules.forEach((rule) => {
                Fetch(`${API_BASE}/rules`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(Object.assign({}, { metricName: formObj.metricName }, { source: source.charts }, { metricId }, rule))
                }).then(ruleId => {
                    this.setState({
                        loadingSubmit: false,
                    });
                    this.props.history.push('/monitor/list', { createdMetric: formObj.metricName });
                });
            });
        }).catch(err => {
            this.setState({
                loadingSubmit: false,
                errorStatus: err.message
            });
            this.addNotification('Error', 'Chart Configuration Could not be submitted: ' + err.message, 'error');
        });
    }

    componentDidMount() {
        this.notificationSystem = this.refs.notificationSystem;
    }

    addNotification(title, msg, lvl) {
        this.notificationSystem.addNotification({
            title,
            message: msg,
            level: lvl,
            autoDismiss: 3,
        });
    }

    render() {
        return (
            <div className="CreateMonitor">
                <MonitorForm
                    chartId={this.props.match.chartId || undefined}
                    onSubmit={this.handleSubmit}
                />
                {
                    this.state.errorStatus &&
                        <span className="error">
                            { this.state.errorStatus }
                        </span>
                }
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default CreateMonitor;