import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';
import RuleItem from '../RuleItem';
import Fetch from '../../../scripts/fetch.js';

import './style.css';

export default class RuleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingRules: false,
            rulesLoaded: false,
            rules: [],
            paginationOffset: 0,
            pageCount: null
        };

        this.handlePageClicked = this.handlePageClicked.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        this.loadRules();
    }

    handlePageClicked(data) {
        const selected = data.selected;
        const offset = Math.ceil(selected * this.props.paginationSize);

        this.setState({ paginationOffset: offset }, () => {
            this.loadRules();
        });
    }

    handleSearchChange(e) {
        e.persist();
        this.debouncedHandleSearchChange(e);
    }

    debouncedHandleSearchChange = _.debounce(e => {
        const searchTerm = e.target.value;
        this.loadRules(searchTerm);
    }, 400);

    loadRules(searchTerm) {
        const paginationSize = this.props.paginationSize || 20;
        const paginationOffset = this.state.paginationOffset;
        const searchQuery = searchTerm && `&search=${encodeURIComponent(searchTerm)}`;
        this.setState({ loadingRules: true });
        return Fetch(`/api/v1/rules?offset=${encodeURIComponent(paginationOffset)}&limit=${encodeURIComponent(paginationSize)}${searchQuery || ''}`, {
            credentials: 'include'
        }).then(data => {
            const rules = data.rules;
            const totalRuleCount = data.totalRuleCount;
            this.setState({
                rules: rules,
                rulesLoaded: true,
                loadingRules: false,
                pageCount: Math.ceil(totalRuleCount / paginationSize)
            });
        }).catch(err => {
            if (err.response.status === 404) {
                this.setState({
                    rules: []
                });
            }
            this.setState({ loadingRules: false });
        });
    }

    render() {
        const rules = this.state.rules;
        const rulesLoaded = this.state.rulesLoaded;
        const pageCount = this.state.pageCount;
        return (
            <div className="RuleList">
                { rulesLoaded &&
                    <div className="search-box-container">
                        <input
                            className="search-box"
                            placeholder="Enter search terms separated by spaces..."
                            onChange={this.handleSearchChange}
                        />
                    </div>
                }
                { rulesLoaded && rules.length > 0 && rules.map(rule => <RuleItem key={rule.ruleId} rule={rule} />) }
                { rulesLoaded && rules.length > 0 &&
                    <ReactPaginate
                        breakLabel={<a href="">...</a>}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClicked}
                        containerClassName={"rule-pagination"}
                        subContainerClassName={"pages rule-pagination"}
                        activeClassName={"active-page"}
                    />
                }
            </div>
        );
    }
}