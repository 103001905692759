import React, { PureComponent } from 'react';

import './style.css';

export default class GettingStarted extends PureComponent {
    render() {
        return (
            <div className="GettingStarted main">
                <div>
                    <h1>
                        Getting Started
                    </h1>
                    <p className="subtext">
                        A quick-start guide on setting up monitors and alerts for your metrics via I.A.N.
                    </p>
                </div>

                <div className="container-fluid" >
                    <div className="form-group row">
                        <h2>Key Concepts</h2>
                    </div>

                    <div className="form-group row">
                        <h3 className="definitions">Metric: </h3>
                        <div className="col-12 def-content">Describes what needs to be monitored.</div>
                    </div>

                    <div className="form-group row">
                        <h3 className="definitions">Data Source: </h3>
                        <div className="col-12 def-content">The DataStore we will pull data from. Currently, <a href='https://charts/'>https://charts</a> is supported via UI.  We also support data ingestion via api. Contact <a href='mailto:ian_support@microsoft.com'>IAN</a> alias to ask about different options for integrating.</div>
                    </div>

                    <div className="form-group row">
                        <h3 className="definitions">Monitor: </h3>
                        <div className="col-12 def-content">Algorithms the system uses to detect if your metric has regressed from your target value.</div>
                    </div>

                    <div className="form-group row">
                        <h3 className="definitions">Signal: </h3>
                        <div className="col-12 def-content">The name of the data attribute being monitored. For example, if the data source is a SQLized table, the signal can be one of the columns that outputs a certain value. </div>
                    </div>

                    <div className="form-group row">
                        <h3 className="definitions">Dimension: </h3>
                        <div className="col-12 def-content">Add richer context to monitors. This is really helpful when receiving alerts.</div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="form-group row">
                        <h2>Wiki</h2>
                    </div>

                    <div className="form-group row">
                        <div className="col-12">For more in-depth information and explanations, please visit <a href='https://osgwiki.com/wiki/Ian' target='_blank' rel='noopener noreferrer'>the wiki</a></div>
                    </div>
                </div>

                <div className="container-fluid" >
                    <div className="form-group row">
                        <h2>Tutorial</h2>
                    </div>
                    <div className="form-group row def-content">
                        Check out this quick video to see how to create monitoring for your metric.
                    </div>
                    <video controls>
                        <source src={require('../../resources/tutorial.mp4')} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
               
                </div>
            </div>
        );
    }
}