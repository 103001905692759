import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';
import Fetch from '../../scripts/fetch.js';
import { API_BASE } from '../../scripts/constants';
import { source } from '../../scripts/constants';
import AeroMonitorForm from '../AeroMonitorForm';

import './style.css';

class EditAeroMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metricId: parseInt(this.props.match.params.MetricId, 10) || 0,
            rules: [], // written to by handleRulesFetched. basically acts as snapshot of existing rules configuration
            ruleOptionSpecifications: {},
            errorStatus: ''
        };

        this.handleRulesFetched = this.handleRulesFetched.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.notificationSystem = null;
        this.notificationStyle = {
            'notification-success:before': {
                content: '',
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ createdMetric: null });
        }
    }

    handleSubmit(formObj) {
        this._submitForm(formObj);
    }

    handleRulesFetched(rules) {
        this.setState({
            rules: rules
        });
    }

    _submitForm(formObj) {
        const toBeDeletedRules = this.state.rules.filter(rule => !_.find(formObj.rules, { ruleId: rule.ruleId })); // rules you started with that don't include the rules that the user is submitting
        this.setState({
            loadingSubmit: true,
            metricName: formObj.metricName,
            rulesCounter: 0,
            totalRules: formObj.rules.length + toBeDeletedRules.length
        }, () => this._submitRules(formObj, toBeDeletedRules));
    }

    _submitRules(formObj, toBeDeletedRules) {
        this.setState({
            loadingSubmit: true
        });
        const opts = Object.assign({
            configId: this.state.aeroMetricPullerId
        }, _.omit(formObj, 'rules'), { source: source.aero });
        Fetch(`${API_BASE}/metricPuller/update`, { // TODO: pass metricId and aeroConfigId
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(opts)
        }).then((metricId) => {
            formObj.rules.forEach((rule) => {
                Fetch(`${API_BASE}/rules`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(Object.assign({}, { source: source.aero }, { metricId }, rule, { metricName: formObj.metricName }))
                }).then(ruleId => {
                    this.setState({
                        loadingSubmit: false,
                    }, () => this._updateRulesCounter());
                }).catch(err => {
                    this.addNotification('Error', 'Aero configuration could not be submitted', 'error');
                    this.setState({
                        loadingSubmit: false,
                        errorStatus: err.message
                    }, () => this._updateRulesCounter());
                });
            });
            toBeDeletedRules.forEach((rule) => {
                Fetch(`${API_BASE}/rules/delete/${rule.ruleId}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then(ruleId => {
                    this.setState({
                        loadingSubmit: false,
                        rule: Object.assign({}, rule, {
                            ruleId: ruleId
                        })
                    }, () => this._updateRulesCounter());
                }).catch(err => {
                    this.addNotification('Error', 'aero could not be deleted', 'error');
                    this.setState({
                        loadingSubmit: false,
                        errorStatus: err.message
                    }, () => this._updateRulesCounter());
                });
            });
            this.props.history.push('/monitor/list', { editedMetric: formObj.metricName });
        }).catch(err => {
            this.addNotification('Error', 'chart could not be updated', 'error');
            this.setState({
                loadingSubmit: false,
                errorStatus: err.message
            });
        });
    }

    _updateRulesCounter() {
        this.setState((prevState, props) => ({
            rulesCounter: prevState.rulesCounter + 1
        }), () => this._checkCompletion());
    }

    _checkCompletion(metricName) {
        if (this.state.rulesCounter === this.state.totalRules) {
            this.addNotification('Success!!', 'Aero configuration updated', 'success');
            this.props.history.push('/monitor/list', { editedMetric: this.state.metricName });
        }
    }

    componentDidMount() {
        this.notificationSystem = this.refs.notificationSystem;
    }

    addNotification(title, msg, lvl) {
        this.notificationSystem.addNotification({
            title: title,
            message: msg,
            level: lvl,
            autoDismiss: 3,
        });
    }

    render() {
        return (
            <div className="EditAeroMonitor">
                <AeroMonitorForm
                    title={'Edit Monitors'}
                    metricId={this.state.metricId}
                    onRulesFetched={this.handleRulesFetched}
                    onSubmit={this.handleSubmit}
                    loadingSubmit={this.state.loadingSubmit}
                />
                {
                    this.state.errorStatus && <span className="error-status">{this.state.errorStatus}</span>
                }
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default EditAeroMonitor;