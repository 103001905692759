import React, { PureComponent } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import './style.css';
import Dropdown from '../Dropdown';

export default class FilterInputs extends PureComponent {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddNew = this.handleAddNew.bind(this);
        this.possibleOperators = ['<', '<=', '=', '>=', '>'];
    }

    handleInputChange(val, rowIndex, colIndex) {
        if (!this.props.readonly) {
            const items = this.props.items;
            this.props.items[rowIndex][colIndex] = val;
            this.props.onItemsChanged(items);
        }
    }

    handleAddNew() {
        const items = this.props.items;
        items.push(['', '=', '']);
        this.props.onItemsChanged(items);
    }

    handleRemoveRow(rowIdx) {
        const items = this.props.items;
        if (items.length > 1) {
            items.splice(rowIdx, 1);
            this.props.onItemsChanged(items);
        }
    }

    render() {
        const items = this.props.rule.filters || [['', '=', '']]; // 2d list of rows cols
        const numColumns = this.props.numColumns || 3;
        const placeholders = this.props.placeholders || _.range(numColumns).map(() => '');
        const readonly = this.props.readonly || false;
        const columnSizes = [5, 2, 5];
        const canAddNewFilter = this.props.canAddNewFilter;

        const rows = items.map((row, rowIndex) => (
            <div key={rowIndex} className="row form-group">
                <div className="col-sm-10 column-section">
                    {row.map((col, colIndex) => (
                        <div key={colIndex} className={'col-sm-' + columnSizes[colIndex]}>
                            { colIndex === 0 &&
                                <Dropdown
                                    id="filterName"
                                    possibleValues={this.props.dimensionColumns.map((item) => ({ ID: item, Name: item }))}
                                    selectedValue={this.props.items[rowIndex][colIndex] || ''}
                                    onChange={e => this.handleInputChange(e.target.value, rowIndex, colIndex)}
                                />
                            }
                            { colIndex === 1 &&
                                <Dropdown
                                    id="filterOperator"
                                    possibleValues={this.possibleOperators.map((item) => ({ ID: item, Name: item }))}
                                    selectedValue={this.props.items[rowIndex][colIndex] || ''}
                                    placeholder='?'
                                    onChange={e => this.handleInputChange(e.target.value, rowIndex, colIndex)}
                                />
                            }
                            { colIndex === 2 &&
                                <input className={classnames('form-control', { 'readonly': readonly })} value={this.props.items[rowIndex][colIndex]} placeholder={placeholders[colIndex]} onChange={(e) => this.handleInputChange(e.target.value, rowIndex, colIndex)} />
                            }
                        </div>
                    ))}
                </div>
                {items.length > 1 &&
                    <div className="col-sm-2 remove-row" onClick={() => this.handleRemoveRow(rowIndex)}>
                        <div className="remove-button">
                            &times;
                        </div>
                    </div>
                }
            </div>
        ));
        return (
            <div className="FilterInputs">
                { rows }
                <div className={'row add-button-container col-sm-3 '}>
                    <div className={'add-button ' + (!canAddNewFilter ? 'fade-add' : '')} onClick={() => this.handleAddNew()}>
                        <div className={'add-button-icon ' + (!canAddNewFilter ? 'fade-add' : '')}>
                            &#xECC8;
                        </div>
                        <span className={'add-button-text ' + (!canAddNewFilter ? 'fade-add' : '')}>
                            Add Another Filter
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}