import React, { Component } from 'react';
import Loading from 'react-loading-spinner';
import Fetch from '../../scripts/fetch';
import Dropdown from '../Dropdown';
import ColumnInputs from '../ColumnInputs';
import { parseQueryParams } from '../../scripts/utils.js';
import { API_BASE } from '../../scripts/constants.js';
import './style.css';

// TODO: move this to a constants file or pull from server if needed
const possibleOperators = ['>', '>=', '=', '<', '<='];

/**
 * Create Rule Form
 *
 * Currently allows for only creating of threshold rules. Code will need to be refactored
 * in order to support different types of rules
 */
export default class CreateRule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possibleMetrics: [],
            rule: {
                ruleId: this.props.match.params.ruleId || null,
                ruleType: 'threshold',
                metricName: parseQueryParams(this.props.location.search).get('metricName') || '',
                filters: [],
                criteria: {
                    threshold: '',
                    operator: ''
                },
            }
        };

        this.handleFiltersChanged = this.handleFiltersChanged.bind(this);
        this.handleRuleTypeChanged = this.handleRuleTypeChanged.bind(this);
        this.handleOperatorChanged = this.handleOperatorChanged.bind(this);
        this.handleThresholdChanged = this.handleThresholdChanged.bind(this);
        this.handleMetricNameChanged = this.handleMetricNameChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const ruleId = this.state.rule.ruleId;
        const loadingRule = ruleId !== null && ruleId !== undefined;
        this.setState({
            loadingMetrics: true,
            loadingRule: loadingRule
        });
        if (loadingRule) {
            Fetch(`/api/v1/rules/${ruleId}`, {
                credentials: 'include'
            }).then(data => {
                const rule = data.rule;
                const ruleUICriteria = data.ruleUICriteria;
                this.setState({
                    loadingRule: false,
                    rule: rule,
                    ruleUICriteria: ruleUICriteria
                });
            });
        }
        Fetch('/api/v1/metrics', { credentials: 'include' }).then(metrics => {
            this.setState({
                loadingMetrics: false,
                possibleMetrics: metrics.map(metric => metric.MetricName)
            });
        }).catch(err => {
            this.setState({
                loadingMetrics: false
            });
        });
    }

    handleFiltersChanged(filtersArray) {
        const rule = Object.assign({}, this.state.rule, {
            filters: filtersArray.map(filterRow => ({
                name: filterRow[0],
                value: filterRow[1]
            }))
        });
        this.setState({
            rule: rule
        });
    }

    handleRuleTypeChanged(e) {
        const ruleType = e.target.value;
        const rule = Object.assign({}, this.state.rule, {
            ruleType: ruleType
        });
        this.setState({
            rule: rule
        });
    }

    handleOperatorChanged(e) {
        const operator = e.target.value;
        const rule = Object.assign({}, this.state.rule, {
            criteria: Object.assign({}, this.state.rule.criteria, {
                operator: operator
            })
        });
        this.setState({
            rule: rule
        });
    }

    handleThresholdChanged(e) {
        const threshold = e.target.value;
        const rule = Object.assign({}, this.state.rule, {
            criteria: Object.assign({}, this.state.rule.criteria, {
                threshold: threshold
            })
        });
        this.setState({
            rule: rule
        });
    }

    handleMetricNameChanged(e) {
        const rule = Object.assign({}, this.state.rule, {
            metricName: e.target.value
        });
        this.setState({
            rule: rule
        });
    }

    handleSubmit() {
        this.setState({
            loadingSubmit: true
        });
        Fetch(`${API_BASE}/rules`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.rule)
        }).then(ruleId => {
            this.setState({
                loadingSubmit: false,
                rule: Object.assign({}, this.state.rule, {
                    ruleId: ruleId
                })
            });
            this.props.history.push(`/rule/edit/${ruleId}`);
        }).catch(err => {
            this.setState({
                loadingSubmit: false,
                errorStatus: err.message
            });
        });
    }

    render() {
        const rule = this.state.rule;
        const creatingRule = rule.ruleId === null;
        const possibleMetrics = this.state.possibleMetrics || [];
        return (
            <div className="CreateRule">
                <div className="rule-form-header">
                    <h1>
                        { creatingRule ? 'Create New Rule' : `Editing Rule ${rule.ruleId}` }
                    </h1>
                </div>
                { rule &&
                    <form className="CreateRuleForm container-fluid">
                        <div className="form-group row">
                            <label htmlFor="metric" className="col-sm-2 control-label">Metric:</label>
                            <div className="col-sm-4">
                                <Dropdown
                                    placeholder="Select a metric"
                                    possibleValues={possibleMetrics.map(metric => ({ ID: metric, Name: metric }))}
                                    selectedValue={this.state.rule.metricName}
                                    onChange={this.handleMetricNameChanged}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="formLabel">Filters</label>
                            <p className="subtext">
                                You can filter the metric values this rule is concerned with using filters. Currently, the only supported filter type
                                is a 1:1 equality operator from filter to the metric's dimensions. e.g. Filter <code>AppName: Photos</code> means look at just
                                the metrics which have dimension <code>AppName: Photos</code>.
                            </p>
                            <ColumnInputs
                                numColumns={2}
                                placeholders={['Filter Key', 'Filter Value']}
                                items={rule.filters.map(filter => [filter.name, filter.value])}
                                onItemsChanged={this.handleFiltersChanged}
                            />
                        </div>
                        <div className="form-group">
                            <label className="formLabel">Alert Criteria</label>
                            <div className="container-fluid alert-criteria-inputs">
                                <div className="row rule-type form-group">
                                    <div className="col-sm-2 control-label">
                                        <label htmlFor="ruleType">Rule Type:</label>
                                    </div>
                                    <div className="col-sm-4 control">
                                        <Dropdown
                                            id="ruleType"
                                            placeholder={'Select a rule type'}
                                            possibleValues={[{ ID: 'threshold', Name: 'Threshold' }]}
                                            selectedValue={rule.ruleType}
                                            onChange={this.handleRuleTypeChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row operator form-group">
                                    <div className="col-sm-2 control-label">
                                        <label htmlFor="operator">Operator:</label>
                                    </div>
                                    <div className="col-sm-4 control">
                                        <Dropdown
                                            placeholder={'Select an operator'}
                                            possibleValues={possibleOperators.map(operator => ({ ID: operator, Name: operator }))}
                                            selectedValue={rule.criteria.operator}
                                            onChange={this.handleOperatorChanged}
                                        />
                                    </div>
                                </div>
                                <div className="row threshold-value form-group">
                                    <label htmlFor="threshold" className="col-sm-2 control-label">Threshold:</label>
                                    <div className="col-sm-4">
                                        <input
                                            id="threshold"
                                            className="form-control"
                                            placeholder="Value must be a number"
                                            value={rule.criteria.threshold}
                                            onChange={this.handleThresholdChanged} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Loading
                            isLoading={this.props.loadingSubmit}
                            loadingClassName='loading'
                        >
                            <button disabled={this.state.loadingSubmit} className="btn btn-default form-control" onClick={this.handleSubmit}>Submit</button>
                            { !this.state.loadingSubmit && this.state.errorStatus && <span className="error-status">{ this.state.errorStatus }</span> }
                        </Loading>
                    </form>
                }
            </div>
        );
    }
}