import React, { PureComponent } from 'react';

import './style.css';

export default class Filters extends PureComponent {
    render() {
        const appNames = [this.props.appName];
        const signals = [this.props.signal];
        const platforms = [this.props.platform];
        const appVersions = this.props.appVersions;
        const buckets = this.props.buckets;
        const OSReleases = this.props.OSReleases;

        // Extend all rows to have same number of columns or else the table will stop rendering each row after its last element
        const numColumns = Math.max(appVersions.length, buckets.length, OSReleases.length);
        while (appNames.length < numColumns) appNames.push('');
        while (signals.length < numColumns) signals.push('');
        while (platforms.length < numColumns) platforms.push('');
        while (appVersions.length < numColumns) appVersions.push('');
        while (buckets.length < numColumns) buckets.push('');
        while (OSReleases.length < numColumns) OSReleases.push('');
        return (
            <div className={'Filters'}>
                <table className={'table table-striped'}>
                    <tbody>
                        <tr>
                            <td>App Name</td>
                            { appNames.map( (appName, i) => {
                                return <td key={i}>{ appName }</td>;
                            })}
                        </tr>
                        <tr>
                            <td>Metric</td>
                            { signals.map( (signal, i) => {
                                return <td key={i}>{ signal }</td>;
                            })}
                        </tr>
                        <tr>
                            <td>App Versions</td>
                            { appVersions.map( (appVersion, i) => {
                                return <td key={i}>{ appVersion }</td>;
                            })}
                        </tr>
                        <tr>
                            <td>Flight Rings</td>
                            { buckets.map( (bucket, i) => {
                                return <td key={i}>{ bucket }</td>;
                            })}
                        </tr>
                        <tr>
                            <td>OS Releases</td>
                            { OSReleases.map( (release, i) => {
                                return <td key={i}>{ release }</td>;
                            })}
                        </tr>
                        <tr>
                            <td>Platform</td>
                            { platforms.map( (platform, i) => {
                                return <td key={i}>{ platform }</td>;
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}