import React, { Component } from 'react';
import Loading from 'react-loading-spinner';
import classnames from 'classnames';
import Fetch from '../../scripts/fetch.js';
import { API_BASE } from '../../scripts/constants';
import CriteriaOptions from './CriteriaOptions';
import Filters from './Filters';
import HistoryTable from './HistoryTable';
import EmbeddedChart from '../utilities/EmbeddedChart';
import Select from 'react-select';

import './style.css';

class ViewRule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            metricId: props.match.params.MetricId,

            loadingRules: false,
            errorRules: false,
            rulesStatusMessage: '',

            loadingAlerts: false,
            errorAlerts: false,
            alertsStatusMessage: '',

            loadingDimensions: false,
            errorDimensions: false,
            dimensionsStatusMessage: '',

            renderRules: false,
            renderAlerts: false,

            metric: {
                value: '',
                link: '',
            },
            filters: [],
            criteria: {
                name: '',
                threshold: '',
                operator: '',
                extendedOptions: {},
            },
            signal: '',
            ruleRuns: {
                ruleId: props.match.params.RuleId,
                alerts: [],
            },

            dimensions: [],
            selectedDimensions: []
        };
        this.handleDimensionChanged = this.handleDimensionChanged.bind(this);
    }

    componentDidMount() {
        const metricId = this.props.match.params.MetricId;
        if (metricId) {
            this._fetchRule(metricId);
            this._fetchAlertsForRule(metricId);
        }
    }

    _fetchRule(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        this.setState({
            loadingRules: true,
            errorRules: false,
            rulesStatusMessage: ''
        });
        return Fetch(`${API_BASE}/rules?metricId=${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const rule = data.rules[0];
            var criteria = {};
            criteria.name = rule.ruleType;
            criteria.baseOptions = {};
            criteria.extendedOptions = rule.extendedOptions;
            Object.keys(rule.criteria).forEach(key => {
                criteria.baseOptions[key] = rule.criteria[key];
            });
            let filters = [];

            rule.filters.forEach(item => {
                filters.push(item.value.Name + item.value.Operator + item.value.Value);
            });
            this.setState({
                metricId: rule.metricId,
                loadingRules: false,
                renderRules: true,
                metric: {
                    value: rule.metricName,
                },
                criteria: criteria,
                filters: filters,
                signal: rule.signal,
                variables:
                [
                    {
                        'name': 'MetricId',
                        'value': rule.metricId
                    },
                    {
                        'name': 'Lookback',
                        'value': 28
                    },
                    {
                        'name': 'Dimensions',
                        'value': ''
                    }
                ]
            });
            this._fetchDimensions(metricId);
        }).catch(err => {
            this.setState({
                loadingRules: false,
                errorRules: true,
                rulesStatusMessage: err,
            });
        });
    }

    _fetchAlertsForRule(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        this.setState({
            loadingAlerts: true,
            errorAlerts: false,
            alertsStatusMessage: ''
        });
        return Fetch(`${API_BASE}/alerts/${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const alerts = data;
            var alertItems = [];
            if (alerts) {
                alertItems = alerts.map((item) => this.formatAlert(item));
            }

            this.setState({
                loadingAlerts: false,
                renderAlerts: true,
                ruleRuns: {
                    alerts: alertItems,
                }
            });
        }).catch(err => {
            this.setState({
                loadingAlerts: false,
                errorAlerts: true,
                alertsStatusMessage: err,
            });
        });
    }

    formatAlert(rawAlert) {
        var payload = JSON.parse(rawAlert.AlertPayload);
        const alertObj = {
            AlertRecordId: rawAlert.AlertRecordId,
            CreatedAt: rawAlert.CreatedAt,
            MetricValue: payload.triggeringValue,
            IsNew: rawAlert.IsNew,
        };
        delete payload.alertCriteria;
        delete payload.filters;
        delete payload.triggeringValue;
        alertObj.extendedOptions = JSON.stringify(payload);
        return alertObj;
    }

    _fetchDimensions(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        this.setState({
            loadingDimensions: true,
            errorDimensions: false,
            dimensionsStatusMessage: ''
        });
        return Fetch(`${API_BASE}/metrics/possibleDimensions/${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const dimensions = data.Dimensions.map(dimension => (
                {
                    value: dimension.name,
                    label: dimension.name
                }
            ));
            const variables = this._updateVariables(dimensions);
            this.setState({
                dimensions: dimensions,
                selectedDimensions: dimensions,
                loadingDimensions: false,
                errorDimensions: false,
                dimensionsStatusMessage: '',
                variables: variables
            });
        }).catch(err => {
            this.setState({
                loadingDimensions: false,
                errorDimensions: true,
                dimensionsStatusMessage: err,
            });
        });
    }

    _updateVariables(dimensions) {
        const dimensionVariable = dimensions.map(dim => dim.value).join(',');
        return this.state.variables.map(item => {
            if (item.name === 'Dimensions') item.value = dimensionVariable;
            return item;
        });
    }

    handleDimensionChanged(values) {
        const variables = this._updateVariables(values);
        this.setState({
            selectedDimensions: values,
            variables: variables
        });
    }

    render() {
        const renderContent = !this.state.loadingRules && !this.state.loadingAlerts && this.state.renderRules && this.state.renderAlerts && !this.state.errorRules && !this.state.errorAlerts;
        var loadingBoxClasses = classnames(
            'status-container',
            {
                'alert alert-danger': this.state.errorRules || this.state.errorRules
            }
        );

        return (
            <div className="ViewRule main">
                <div className="rule-form-header">
                    <h1>
                        {this.state.metric.value}
                    </h1>
                    <p className="subtext">
                        View the set rules for this monitor and view previous alerts that have fired.
                    </p>
                </div>

                {renderContent &&
                    <div className="container-fluid" >
                        <div className="form-group row">
                            <h4>Filters: </h4>
                            <span className={'col-sm-12'}>
                                <Filters value={this.state.filters}/>
                            </span>
                        </div>

                        <div className="form-group row">
                            <h4>Rule: {this.state.criteria.name}</h4>
                            <CriteriaOptions criteria={this.state.criteria} signal={this.state.signal} />
                        </div>
                        <EmbeddedChart
                            id={'history-chart'}
                            chartId={50806}
                            variables={this.state.variables}
                            className={'embedded-chart'}
                        />
                        { !this.state.loadingDimensions && !this.state.errorDimensions && this.state.dimensions &&
                            <div className={'form-group row'}>
                                <label htmlFor={'dimensions-select'} className={'col-sm-2 control-label'}>Dimensions</label>
                                <Select
                                    name={'dimensions-select'}
                                    placeholder={'Select Dimensions'}
                                    className={'col-sm-10'}
                                    multi={true}
                                    closeOnSelect={false}
                                    value={this.state.selectedDimensions}
                                    onChange={this.handleDimensionChanged}
                                    options={this.state.dimensions}
                                />
                            </div>
                        }
                        {
                            this.state.dimensionsStatusMessage
                        }
                        <hr />
                        <div className="form-group row">
                            <h4>Alert History</h4>
                            <HistoryTable alerts={this.state.ruleRuns.alerts} />
                        </div>
                    </div>
                }
                <Loading
                    isLoading={this.state.loadingRules || this.state.loadingAlerts}
                    loadingClassName={'loading'}
                >
                    <div className={loadingBoxClasses}>
                        {
                            this.state.rulesStatusMessage &&
                            <div className={classnames({ 'error-status': this.state.errorRules })}>{ this.state.rulesStatusMessage }</div>
                        }
                        {
                            this.state.alertsStatusMessage &&
                            <div className={classnames({ 'error-status': this.state.errorRules })}>{ this.state.alertsStatusMessage }</div>
                        }
                    </div>
                </Loading>
            </div>
        );
    }
}

export default ViewRule;
