import React from 'react';
import classnames from 'classnames';

import './style.css';

/**
 *
 * props:
 * - label {string} - Label of dropdown
 * - onChange {function} - callback function which is passed argument `selectedValue`. `All` selection will pass back null
 * - possibleValues {Array} - Array of {ID:, Name:} pairs
 * - selectedValue {string} - ID corresponding to what value should be displayed as selected
 * - placeholder {string} - Placeholder text when nothing is selected
 */
export default function Dropdown(props) {
    return (
        <div className={ classnames('Dropdown', 'form-group') }>
            { props.label && <label htmlFor='e2e-dropdown'>{ props.label }</label> }
            <select id={props.id} className={classnames('form-control', props.className)} required value={ props.selectedValue || '' } onChange={ props.onChange }>
                <option value='' disabled>{ props.placeholder || 'Nothing selected.' }</option>
                { props.possibleValues.map((value) =>
                    <option value={ value.ID } key={ value.ID }>
                        { value.Name }
                    </option>
                )}
            </select>
        </div>
    );
}