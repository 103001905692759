import 'url-search-params-polyfill';  // require polyfill for URLSearchParams for Edge and IE support

/**
 * Parses given query string and just returns a URLSearchParams object.
 * @example
 * // queryString -> '?param1=value1&param2=value2'
 * params = parseQueryParams(queryString);
 * params.get('param1'); // -> returns value1
 * @param {Object} queryParamString - URLSearchParams object
 */
export function parseQueryParams(queryParamString) {
    return new URLSearchParams(queryParamString);
}