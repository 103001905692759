import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class EmbeddedChart extends PureComponent {
    static propTypes = {
        /* id of chart to embed */
        chartId: PropTypes.number.isRequired,
        /* id to put on DOM element */
        id: PropTypes.string.isRequired,
        /* array of objects with keys 'name' and 'value' representing input variables */
        variables: PropTypes.arrayOf(PropTypes.object),
        /* className to pass through to DOM element */
        className: PropTypes.string,
        /* selector to pass to chart defaults to '#id' */
        selector: PropTypes.string,
    }

    componentWillMount() {
        this.loadChart();
    }

    /**
     * When the props change, make sure to update chart. For efficiency's sake, use the global charts object API
     * instead of re-adding another script element for /embed/:id.js.
     *
     * TODO: ensure the charts global object has been defined already
     */
    componentDidUpdate(prevProps) {
        if (prevProps.variables !== this.props.variables) {
            this.loadChart(true);
        }
    }

    loadChart(isUpdate) {
        const selector = this.props.selector || `#${this.props.id}`;
        /* transform variables into form charts.loadChart expects */
        const customVariables = this.props.variables && this.props.variables.reduce((obj, variable) => {
            obj[variable.name] = variable.value;
            return obj;
        }, {});
        if (window.charts) {
            if (isUpdate) {
                window.charts.updateChart(selector, this.props.chartId, customVariables || {});
            }
            else {
                window.charts.loadChart(selector, this.props.chartId, customVariables || {});
            }
        }
        else {
            console.error('charts global object is not yet defined...');
        }
    }

    render() {
        const id = this.props.id;
        const className = this.props.className;
        return (
            <div className={'EmbeddedChart'}>
                <div className={className} id={id}></div>
            </div>
        );
    }
}