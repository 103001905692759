import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Dropdown from '../Dropdown';
import constants from '../../scripts/constants.js';

import './style.css';

export default class RuleOption extends PureComponent {
    static propTypes = {
        optionSpecification: PropTypes.object.isRequired,
        currentValue: PropTypes.any,
        extendedOptions: PropTypes.object,
        dimensionColumns: PropTypes.array,
        onOptionChange: PropTypes.func.isRequired,
        onExtendedOptionsChange: PropTypes.func
    };

    render() {
        if (!this.props.optionSpecification || ('OptionVisible' in this.props.optionSpecification && !this.props.optionSpecification.OptionVisible)) {
            return null;
        }
        const optionType = this.props.optionSpecification.OptionType;
        const optionName = this.props.optionSpecification.OptionName;
        const optionFriendlyName = this.props.optionSpecification.OptionUIFriendlyName || optionName;
        const optionDescription = this.props.optionSpecification.OptionUIDescription;
        const required = this.props.optionSpecification.Required;
        const currentOptionValue = this.props.currentValue;
        let extendedOptions = this.props.extendedOptions;
        const extendedOptionsSpecification = this.props.optionSpecification.ExtendedOptions;
        const dimensionColumns = this.props.dimensionColumns || [];

        // special case - the useDimensionValue dimension will change behavior of the option control
        let optionUsesDimension = false;
        if (extendedOptions) {
            optionUsesDimension = !!extendedOptions.useDimensionValue;
        }
        const errorString = 'Required field';
        const errorInOption = required && (currentOptionValue === null || currentOptionValue === undefined || currentOptionValue === '');

        return (
            <div className={classnames('RuleOption', { 'error': errorInOption }, this.props.containerClassName)} >
                <ReactTooltip id='data-tooltip' />
                <div className={classnames('rule-option-label', this.props.labelClassName)}>
                    <label htmlFor={`${optionName}-control`} data-tip={optionDescription} data-for='data-tooltip'>
                        { optionFriendlyName }
                        { errorInOption &&
                            <span className='error-string'><br />{errorString}</span>
                        }
                    </label>
                </div>
                <div className={classnames('rule-option-control', this.props.controlClassName)} >
                    {
                        optionUsesDimension &&
                            <div className="DimensionOption">
                                {
                                    dimensionColumns.length === 0 &&
                                        <span className="dimension-option-null-message">No dimension options loaded or specified</span>
                                }
                                {
                                    dimensionColumns.length > 0 &&
                                        <Dropdown
                                            possibleValues={dimensionColumns.map(col => ({ ID: col, Name: col }))}
                                            selectedValue={currentOptionValue}
                                            onChange={(e) => this.props.onOptionChange(e.target.value)}
                                        />
                                }
                            </div>
                    }
                    {
                        !optionUsesDimension && optionType === constants.RULE_OPTION_TYPES.Number &&
                            <div className="NumberRuleOption">
                                <input
                                    id={`${optionName}-control`}
                                    className={'form-control'}
                                    type='number'
                                    placeholder={optionDescription}
                                    value={currentOptionValue}
                                    onChange={(e) => this.props.onOptionChange(e.target.value)}
                                />
                            </div>
                    }
                    {
                        !optionUsesDimension && optionType === constants.RULE_OPTION_TYPES.Boolean &&
                            <div className="BooleanRuleOption">
                                <input
                                    type="checkbox"
                                    name={this.props.optionSpecification.OptionUIField}
                                    checked={!!currentOptionValue}
                                    onChange={(e) => this.props.onOptionChange(!currentOptionValue)}
                                />
                            </div>
                    }
                    {
                        !optionUsesDimension && optionType === constants.RULE_OPTION_TYPES.Set &&
                            <div className="SetRuleOption">
                                <Dropdown
                                    placeholder={'Select an operator'}
                                    possibleValues={this.props.optionSpecification.SetMembers.map(setMember => ({ ID: setMember, Name: setMember }))}
                                    selectedValue={currentOptionValue}
                                    onChange={(e) => this.props.onOptionChange(e.target.value)}
                                />
                            </div>
                    }
                </div>
                {
                    extendedOptions &&
                        <div className={classnames('rule-option-extended', this.props.extendedOptionsClassName)}>
                            {
                                Object.keys(extendedOptions).map((extendedOption, index) => (
                                    <RuleOption
                                        key={index}
                                        optionSpecification={extendedOptionsSpecification.find(spec => spec.OptionUIField === extendedOption)}
                                        currentValue={extendedOptions[extendedOption]}
                                        onOptionChange={(newValue) => this.props.onExtendedOptionsChange(extendedOption, newValue)}
                                    />
                                ))
                            }
                        </div>
                }
            </div>
        );
    }
}