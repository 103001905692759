import React, { PureComponent } from 'react';

import './style.css';

export default class RuleTypes extends PureComponent {
    render() {
        const ruleTypes = this.props.ruleTypes;
        const ruleTypeToString = this.props.ruleTypeToString;
        const ruleTypeDescription = this.props.ruleTypeDescription;
        return (
            <div className={'RuleTypes'}>
                <table className={'table table-striped'}>
                    <tbody>
                        { ruleTypes.map( (ruleType, i) =>
                            <tr key={i}>
                                <td>{ ruleTypeToString[ruleType] }</td>
                                <td>{ ruleTypeDescription[ruleType] }</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}