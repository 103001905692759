import React, { Component } from 'react';
import MonitorItem from '../MonitorItem';
import classnames from 'classnames';
import Loading from 'react-loading-spinner';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';
import Fetch from '../../../scripts/fetch.js';
import constants from '../../../scripts/constants.js';

import './style.css';
import { API_BASE } from '../../../scripts/constants';

export default class MonitorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monitors: [],

            // loading and error validation
            loadingMonitors: false,
            errorMonitors: false,
            monitorsStatusMessage: '',
            loadingGetData: false,
            errorGetData: false,
            getDataStatusMessage: '',
            loadingRunRules: false,
            errorRunRules: false,
            runRulesStatusMessage: '',

            showDevTools: false,
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.handleGetData = this.handleGetData.bind(this);
        this.handleRunRules = this.handleRunRules.bind(this);
    }

    componentDidMount() {
        this.loadMonitors();
        this.notificationSystem = this.refs.notificationSystem;
        if (this.props.redirectedFromCreate && this.props.from === 'PUSH') {
            this.addNotification('Success!!', `Monitors for new metric '${this.props.createdMetric}' were successfully created.`, 'success');
        }
        else if (this.props.redirectedFromEdit && this.props.from === 'PUSH') {
            this.addNotification('Success!!', 'Chart configuration updated', 'success');
        }
    }

    handleDeleteConfirm(metricId) {
        let opts = {
            metricId: metricId
        };
        Fetch(`${API_BASE}/metricPuller/delete/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(opts)
        }).then(signals => {
            this.addNotification('Success!!', 'Configuration deleted', 'success');
            this.loadMonitors();
        }).catch(err => {
            this.addNotification('Error', 'Configuration could not be deleted', 'error');
        });
    }

    handleDeleteCancel() {
        // No Op function to handle deleteCancel
    }

    handleGetData(source, metricId) {
        this.setState({
            loadingGetData: true,
            errorGetData: false,
            getDataStatusMessage: ''
        });
        Fetch(`${API_BASE}/metricPuller/run/${metricId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ source })
        }).then((result) => {
            this.setState({
                loadingGetData: false,
                getDataStatusMessage: `${result.metricValuesInserted} values obtained from chart.`
            });
        }).catch(err => {
            this.setState({
                loadingGetData: false,
                errorGetData: true,
                getDataStatusMessage: `Error getting values from chart: ${err.message ? err.message : err}`
            });
        });
    }

    handleRunRules(metricId) {
        this.setState({
            loadingRunRules: true,
            errorRunRules: false,
            runRulesStatusMessage: ''
        });
        Fetch(`${API_BASE}/rules/run/${metricId}`, { method: 'POST' }).then((results) => {
            this.setState({
                loadingRunRules: false,
                runRulesStatusMessage: `Rules run successfully. ${results.alertsFired} total alerts fired.`
            });
        }).catch(err => {
            this.setState({
                loadingRunRules: false,
                errorRunRules: true,
                runRulesStatusMessage: `Error running rules: ${err.message ? err.message : err}`,
            });
        });
    }

    handleSearchChange(e) {
        e.persist();
        this.debouncedHandleSearchChange(e);
    }

    debouncedHandleSearchChange = _.debounce(e => {
        const searchTerm = e.target.value;
        this.loadChartConfigs(searchTerm);
    }, 400);

    loadMonitors() {
        // TODO: pagination
        this.setState({ loadingMonitors: true });
        return Fetch(`${API_BASE}/rules/user`).then(data => {
            let monitors = data.rules.filter((item, index, self) => index === self.findIndex((t) => t.metricId === item.metricId));
            monitors = monitors.map(monitor => {
                if (monitor.author === data.user || monitor.role === 'author') {
                    monitor.canDelete = true;
                }
                if (monitor.canDelete || monitor.role === 'contributor') {
                    monitor.canEdit = true;
                }
                return monitor;
            });
            this.setState({
                loadingMonitors: false,
                monitors: monitors,
                showDevTools: constants.AUTHORIZED_USERS.includes(data.user.toLowerCase()),
            });
        }).catch(err => {
            this.setState({
                loadingMonitors: false,
                errorMonitors: true,
                monitorsStatusMessage: err.message ? err.message : err
            });
        });
    }

    addNotification(title, msg, lvl) {
        this.notificationSystem.addNotification({
            title: title,
            message: msg,
            level: lvl,
            autoDismiss: 3,
        });
    }

    render() {
        const monitors = this.state.monitors;
        const monitorsLoaded = !this.state.loadingMonitors;

        return (
            <div className="MonitorList">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Monitor Name</th>
                            <th>Metric</th>
                            <th>Data Source</th>
                            <th></th>{/* Fork */}
                            <th></th>{/* Edit */}
                            <th></th>{/* Delete */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            monitorsLoaded && monitors.length > 0 &&
                                monitors.map(monitor =>
                                    <MonitorItem
                                        key={monitor.metricId}
                                        monitor={monitor}
                                        showDevTools={this.state.showDevTools}
                                        handleDeleteConfirm={() => this.handleDeleteConfirm(monitor.metricId)}
                                        handleDeleteCancel={this.handleDeleteCancel}
                                        onGetData={() => this.handleGetData(monitor.dataSource, monitor.metricId)}
                                        onRunRules={() => this.handleRunRules(monitor.metricId)}
                                    />) }
                    </tbody>
                </table>
                {
                    monitorsLoaded && monitors.length === 0 &&
                        <span>No Monitors found</span>
                }
                <Loading
                    isLoading={this.state.loadingGetData || this.state.loadingMonitors || this.state.loadingRunRules}
                    loadingClassName={'loading'}
                >
                    <div className="status-container">
                        {
                            this.state.getDataStatusMessage &&
                                <div className={classnames({ 'error-status': this.state.errorGetData })}>{ this.state.getDataStatusMessage }</div>
                        }
                        {
                            this.state.runRulesStatusMessage &&
                                <div className={classnames({ 'error-status': this.state.errorRunRules })}>{ this.state.runRulesStatusMessage }</div>
                        }
                        {
                            this.state.metricsStatusMessage &&
                                <div className={classnames({ 'error-status': this.state.errorMetrics })}>{ this.state.metricsStatusMessage }</div>
                        }
                    </div>
                </Loading>
                <NotificationSystem ref="notificationSystem" style={this.notificationStyle} />
            </div>
        );
    }
}

