import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    tenant: 'microsoft.onmicrosoft.com',
    clientId: '4f80cf63-06d9-42a0-a4e9-61569217e991',
    endpoints: {
        api: '4f80cf63-06d9-42a0-a4e9-61569217e991',
    },
    cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
