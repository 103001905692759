import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from '../Dropdown';
import RuleOption from '../RuleOption';
import FilterInputs from '../FilterInputs';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import TagsInput from 'react-tagsinput';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import './style.css';

export default class AlertInputs extends PureComponent {
    static propTypes = {
        rule: PropTypes.shape({
            ruleId: PropTypes.number,
            filters: PropTypes.array,
            ruleType: PropTypes.string.isRequired,
            criteria: PropTypes.object.isRequired,
            extendedOptions: PropTypes.object,
            emailNotify: PropTypes.arrayOf(PropTypes.string)
        }).isRequired,
        dimensionColumns: PropTypes.arrayOf(PropTypes.string),
        ruleOptionSpecification: PropTypes.arrayOf(PropTypes.object),
        onRuleTypeChange: PropTypes.func.isRequired,
        onRuleOptionChange: PropTypes.func.isRequired,
        onRuleExtendedOptionsChange: PropTypes.func,
        onRuleEmailChange: PropTypes.func.isRequired,
        onFiltersChange: PropTypes.func
    };

    render() {
        const emails = this.props.rule.emailNotify || [];
        const scheduleTypeMap = this.props.possibleRuleScheduleTypes || {};
        if (!this.props.rule.filters || this.props.rule.filters.length === 0) {
            this.props.rule.filters = [['', '=', '']];
        }
        const emailSetValid = this.props.rule.isValidEmailSet;
        const canAddNewFilter = !this.props.rule.filters.some(item => !item[0] || !item[2]);
        const emailInputClass = emailSetValid ? 'input-good' : 'input-error';
        return (
            <div className="AlertInputs container-fluid alert-criteria-inputs">
                <div className="row rule-type form-group">
                    <div className="col-sm-2 control-label">
                        <label htmlFor="ruleType">Alert When</label>
                    </div>
                    <div className="col-sm-4 control">
                        <Dropdown
                            id="ruleType"
                            placeholder={'Select a rule type'}
                            possibleValues={[{ ID: 'threshold', Name: 'Threshold Is Crossed' }, { ID: 'nDayThreshold', Name: 'Threshold Is Crossed Over Period' }]}
                            selectedValue={this.props.rule.ruleType}
                            onChange={e => this.props.onRuleTypeChange(e.target.value)}
                        />
                    </div>
                </div>
                <div className="rule-options">
                    { Object.keys(this.props.rule.criteria).map((option, index) => (
                        <RuleOption
                            key={index}
                            containerClassName={classnames('form-group', 'row')}
                            labelClassName={classnames('control-label, col-sm-2')}
                            controlClassName={classnames('control', 'col-sm-4')}
                            dimensionColumns={this.props.dimensionColumns}
                            optionSpecification={this.props.ruleOptionSpecification.find(spec => spec.OptionUIField === option)}
                            currentValue={this.props.rule.criteria[option]}
                            extendedOptions={this.props.rule.extendedOptions && this.props.rule.extendedOptions[option]}
                            onOptionChange={(newValue) => this.props.onRuleOptionChange(option, newValue)}
                            onExtendedOptionsChange={(extendedOptionName, newValue) => this.props.onRuleExtendedOptionsChange(option, extendedOptionName, newValue)}
                        />
                    ))}
                </div>
                <div className="row rule-filters form-group">
                    <div className="col-sm-2 control-label">
                        <label htmlFor="ruleFilters">Filters</label>
                    </div>
                    { this.props.showChartInfoDependents ?
                        <div className="col-sm-10 control">
                            <FilterInputs
                                dimensionColumns={this.props.dimensionColumns || []}
                                numColumns={3}
                                placeholders={['Filter Key', '=', 'Filter Value']}
                                rule={this.props.rule}
                                items={this.props.rule.filters}
                                canAddNewFilter={canAddNewFilter}
                                onItemsChanged={(rule) => this.props.onFiltersChange(this.props.rule)}
                            />
                        </div>
                        : <p>Chart info must be loaded first.</p>
                    }
                </div>

                <div className="rule-email row">
                    <i className="tooltip-hover fa fa-info-circle" aria-hidden="true" data-tip={'Enter comma-separated list of emails to notify when an alert fires'} data-for='email-tooltip'></i>
                    <label className="col-sm-2" htmlFor="email">
                        Emails to Notify
                        { !emailSetValid && <span className='error-string'><br />{'Invalid format'}</span> }
                    </label><ReactTooltip id='email-tooltip' />
                    <div className="col-sm-9 email-input-div">
                        <TagsInput
                            value={emails}
                            onChange={this.props.onRuleEmailChange}
                            addKeys={[9, 13, 32, 186, 188]} // Tab, Enter, Space, Semicolon, Comma
                            addOnBlur={true}
                            inputProps={ { className: 'react-tagsinput-input ' + emailInputClass, placeholder: 'alias@microsoft.com' } }
                        />
                    </div>
                </div>
                <div className="rule-start-date row">
                    <label className="col-sm-2" htmlFor="start-date">Start date</label>
                    <div className="col-sm-3">
                        <DatePicker
                            selected={moment(this.props.rule.startDate)}
                            onChange={this.props.onRuleStartDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="LLL"
                        />
                    </div>
                    <div className="col-sm-3">
                        <div className="row">
                            <label className="col-sm-6 schedule-period-control" htmlFor="schedule-period">Every</label>
                            <div className="col-sm-6">
                                <input
                                    id="schedule-period"
                                    type="number"
                                    className={classnames('form-control schedule-period-control', { 'error': this.props.errorRuleSchedulePeriod })}
                                    value={this.props.rule.ruleSchedulePeriod}
                                    onChange={(e) => this.props.onRuleSchedulePeriodChange(e.target.value)}
                                >
                                </input>
                                {
                                    this.props.ruleSchedulePeriodStatusMessage &&
                                        <div>
                                            <span className={classnames({ 'error': this.props.errorRuleSchedulePeriod})}>{ this.props.ruleSchedulePeriodStatusMessage }</span>
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-2">
                        <Dropdown
                            id="ruleScheduleType"
                            possibleValues={Object.keys(scheduleTypeMap).map((scheduleTypeName) => ({ ID: this.props.possibleRuleScheduleTypes[scheduleTypeName], Name: scheduleTypeName }))}
                            selectedValue={this.props.rule.ruleScheduleType}
                            onChange={e => this.props.onRuleScheduleTypeChange(e.target.value)}
                        />
                    </div>
                </div>
            </div>

        );

    }
}