import React, { PureComponent } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import './style.css';

export default class ColumnInputs extends PureComponent {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddNew = this.handleAddNew.bind(this);
    }

    handleInputChange(rowIdx, colIdx, newValue) {
        const items = this.props.items.slice();
        items[rowIdx][colIdx] = newValue;
        this.props.onItemsChanged(items);
    }

    handleAddNew() {
        const items = this.props.items.slice();
        items.push(_.range(this.props.numColumns || 2).map(() => ''));
        this.props.onItemsChanged(items);
    }

    handleRemoveRow(rowIdx) {
        const items = this.props.items.slice();
        items.splice(rowIdx, 1);
        this.props.onItemsChanged(items);
    }

    render() {
        const items = this.props.items || []; // 2d list of rows cols
        const numColumns = this.props.numColumns || 2;
        const placeholders = this.props.placeholders || _.range(numColumns).map(() => '');
        let readonly = this.props.readonly || false;

        // ensure readonly is an array mapping a boolean to each column
        if (!Array.isArray(readonly)) {
            readonly = _.range(numColumns).map(() => !!readonly);
        }
        const rows = items.map((row, rowIndex) => (
            <div key={rowIndex} className="row form-group">
                <div className="col-sm-10 column-section">
                    {row.map((col, colIndex) => (
                        <div key={colIndex} className={'col-sm-' + Math.floor(12 / numColumns)}>
                            <input className={classnames('form-control', { 'readonly': readonly[colIndex] })} placeholder={placeholders[colIndex]} value={col} onChange={(e) => !readonly[colIndex] && this.handleInputChange(rowIndex, colIndex, e.target.value)} />
                        </div>
                    ))}
                </div>
            </div>
        ));
        return (
            <div className="ColumnInputs container-fluid">
                { rows }
            </div>
        );
    }
}