import React, { Component } from 'react';
import Loading from 'react-loading-spinner';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';
import classnames from 'classnames';
import Fetch from '../../scripts/fetch.js';
import { API_BASE } from '../../scripts/constants';
import { source } from '../../scripts/constants';
import Dropdown from '../Dropdown';
import DatePicker from 'react-datepicker';
import TagsInput from 'react-tagsinput';
import Select from 'react-select';
import moment from 'moment';

import 'react-select/dist/react-select.css';
import './style.css';

class AeroMonitorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aeroConfigId: props.aeroConfigId || null,
            metricName: '',
            metricDescription: '',
            aeroInputVars: [],
            signalColumn: '',
            dimensionColumns: [],
            possibleSignals: [],
            possibleDimensionColumns: [],
            ruleOptionSpecifications: {},
            rules: [],
            emailNotify: [],
            isValidEmailSet: true,
            contributors: [],

            minimumDeviceCount: 25,

            monitorLatestAppVersion: false,

            allRuleTypes: {
                'threshold': {
                    description: 'My metric misses the flight targets',
                    checked: true
                },
                'appVersionCompare': {
                    description: 'My metric regresses from previous app versions',
                    checked: true
                },
                'appVersionTimeSeries': {
                    description: 'My metric regresses from previous app versions over the same period of time after release',
                    checked: true
                },
            },

            showMinimumDeviceCountEntry: false,

            startDate: new Date().toUTCString(),
            ruleSchedulePeriod: 12,
            ruleScheduleType: 1,

            selectedAppName: '',
            selectedAppVersion: [],
            selectedMetric: '',
            selectedRings: [],
            selectedDeviceClass: '',
            selectedOSVersion: [],
            selectedBuckets: [],

            appConfigs: [],
            filteredConfigs: [],
            appNames: [],
            metrics: [],
            appVersions: [],
            deviceClasses: [],
            osVersions: [],
            buckets: [],

            // loading and error states
            loadingMetricConfigInfo: false,
            errorMetricConfigInfo: true,
            metricConfigInfoStatusMessage: '',

            loadingMetricNameCheck: false,
            errorMetricName: true,
            metricNameStatusMessage: '',

            errorSignal: false,
            signalStatusMessage: '',

            errorRuleSchedulePeriod: false,
            ruleSchedulePeriodStatusMessage: '',

            errorMinimumDeviceCount: false,
            MinimumDeviceCountStatusMessage: '',

            loadingAeroMetricConfig: false,
            errorAeroMetricConfig: false,
            errorAeroMetricConfigStatusMessage: '',

            loadingRules: false,
            errorRules: false,
            errorRulesMessage: '',

            loadingRuleOptions: false,
            errorRuleOptions: false,
            errorRuleOptionsStatusMessage: '',
        };

        this.retailAppVersions = {};

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleMetricNameChange = this.handleMetricNameChange.bind(this);
        this.handleRuleTypeChanged = this.handleRuleTypeChanged.bind(this);
        this.handleRuleOptionChange = this.handleRuleOptionChange.bind(this);
        this.handleRuleExtendedOptionChange = this.handleRuleExtendedOptionChange.bind(this);
        this.handleRuleEmailChange = this.handleRuleEmailChange.bind(this);
        this.handleRuleStartDateChange = this.handleRuleStartDateChange.bind(this);
        this.handleRuleScheduuleTypeChange = this.handleRuleScheduleTypeChange.bind(this);
        this.handleRuleSchedlePeriodChange = this.handleRuleSchedulePeriodChange.bind(this);
        this.handleContributorsChange = this.handleContributorsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoadMetricConfigData = this.handleLoadMetricConfigData.bind(this);
        this.handleShowMinimumDeviceCountChanged = this.handleShowMinimumDeviceCountChanged.bind(this);
        this.handleMinimumDeviceCountChanged = this.handleMinimumDeviceCountChanged.bind(this);

        this._filterOption = this._filterOption.bind(this);

        this.configsMonitoringLatest = new Set();

        this.searchableMetric = {
            'Crash-free Background Sessions %': ['cfs'],
            'Crash-free Foreground Sessions %': ['cfs'],
            'Net Star Rating': ['nsr'],
            'UIF Submitted': ['uif'],
            'Engagement Time (Seconds)': ['use', 'usage', 'time'],
            'Active Device Count': ['mad', 'dad', 'adc'],
            'Crash-free Device %': ['cfd'],
            'Accessibility Usage (Hours)': ['use', 'usage', 'time'],
            'Performance (Milliseconds)': ['perf', 'time', 'launch']
        };

        this.createMonitorDescription = 'Create monitors that trigger alerts in real-time when your data meets your rule\'s requirements.';
        this.metricNamePlaceholder = 'ex. %CFS for People app on RS3';
        this.monitorLatestTitleString = 'Always monitor latest app version';
        this.monitorLatestInfoString = 'With this selected, choosing specific app versions is optional';
        this.minDeviceCountTitleString = 'A certain number of active devices is reached';
        this.minDeviceCountInfoString = '(Default 25)';
        this.minDeviceCountPlaceholder = '25';
    }

    handleAppNameChange = (e) => {
        if (!e) e = { value: '' };
        this.setState({
            selectedAppName: e.value,
            selectedAppVersion: [],
            selectedDeviceClass: '',
            selectedRings: [],
            selectedOSVersion: [],
            selectedBuckets: [],
            monitorLatestAppVersion: false,
        }, () => this._filterAppParameters());
    }

    handleMetricChange = (value) => {
        this.setState({ selectedMetric: value }, () => this._filterAppParameters());
    }

    handleAppVersionChange = (values) => {
        if (this.state.monitorLatestAppVersion && !values.map(val => val.value).includes('Latest')) {
            this.handleMonitorLatestChanged();
        }
        this.setState({
            selectedAppVersion: values
        }, () => this._filterAppParameters());
    }

    handleRingsChange = (values) => {
        this.setState({ selectedRings: values }, () => this._filterAppParameters());
    }

    handleDeviceClassChange = (value) => {
        this.setState({ selectedDeviceClass: value }, () => this._filterAppParameters());
    }

    handleOSVersionChange = (values) => {
        this.setState({ selectedOSVersion: values }, () => this._filterAppParameters());
    }

    handleBucketsChange = (values) => {
        this.setState({ selectedBuckets: values }, () => this._filterAppParameters());
    }

    handleShowMinimumDeviceCountChanged = () => {
        this.setState({ showMinimumDeviceCountEntry: !this.state.showMinimumDeviceCountEntry });
    }

    clearAppVersion() {
        this.setState({ selectedAppVersion: '' }, () => this._filterAppParameters());
    }

    clearRings() {
        this.setState({ selectedRings: [] }, () => this._filterAppParameters());
    }

    clearDeviceClass() {
        this.setState({ selectedDeviceClass: '' }, () => this._filterAppParameters());
    }

    clearOSVersion() {
        this.setState({ selectedOSVersion: [] }, () => this._filterAppParameters());
    }

    /**
    * Goal: Update time should be maximum of .015s
    * TODO: Add unit test for this
    */
    _filterAppParameters() {
        let metrics = [];
        let appVersions = [];
        let deviceClasses = [];
        let osVersions = [];
        let buckets = [];

        const latestFilter = (this.state.monitorLatestAppVersion && this.state.selectedAppVersion.length > 1) || (!this.state.monitorLatestAppVersion && this.state.selectedAppVersion.length > 0);

        metrics = this.state.appConfigs.filter(config => {
            if (this.state.selectedAppName && this.state.selectedAppName !== config.AppName) {
                return false;
            }
            if (latestFilter && !this.state.selectedAppVersion.map(item => item.value).includes(config.AppVersion)) {
                return false;
            }
            if (this.state.selectedDeviceClass && this.state.selectedDeviceClass.value !== config.Platform) {
                return false;
            }
            if (this.state.selectedBuckets.length > 0 && !this.state.selectedBuckets.map(item => item.value).includes(config.CurrentBucket)) {
                return false;
            }
            if (this.state.selectedOSVersion.length > 0 && !this.state.selectedOSVersion.map(item => item.value).includes(config.OS)) {
                return false;
            }
            return true;
        }).map(config => config.MetricName);

        appVersions = this.state.appConfigs.filter(config => {
            if (this.state.selectedAppName && this.state.selectedAppName !== config.AppName) {
                return false;
            }
            if (this.state.selectedMetric && this.state.selectedMetric.value !== config.MetricName) {
                return false;
            }
            if (this.state.selectedDeviceClass && this.state.selectedDeviceClass.value !== config.Platform) {
                return false;
            }
            if (this.state.selectedOSVersion.length > 0 && !this.state.selectedOSVersion.map(item => item.value).includes(config.OS)) {
                return false;
            }
            if (this.state.selectedBuckets.length > 0 && !this.state.selectedBuckets.map(item => item.value).includes(config.CurrentBucket)) {
                return false;
            }
            return true;
        }).map(config => config.AppVersion);

        deviceClasses = this.state.appConfigs.filter(config => {
            if (this.state.selectedAppName && this.state.selectedAppName !== config.AppName) {
                return false;
            }
            if (this.state.selectedMetric && this.state.selectedMetric.value !== config.MetricName) {
                return false;
            }
            if (latestFilter && !this.state.selectedAppVersion.map(item => item.value).includes(config.AppVersion)) {
                return false;
            }
            if (this.state.selectedOSVersion.length > 0 && !this.state.selectedOSVersion.map(item => item.value).includes(config.OS)) {
                return false;
            }
            if (this.state.selectedBuckets.length > 0 && !this.state.selectedBuckets.map(item => item.value).includes(config.CurrentBucket)) {
                return false;
            }
            return true;
        }).map(config => config.Platform);

        osVersions = this.state.appConfigs.filter(config => {
            if (this.state.selectedAppName && this.state.selectedAppName !== config.AppName) {
                return false;
            }
            if (this.state.selectedMetric && this.state.selectedMetric.value !== config.MetricName) {
                return false;
            }
            if (latestFilter && !this.state.selectedAppVersion.map(item => item.value).includes(config.AppVersion)) {
                return false;
            }
            if (this.state.selectedDeviceClass && this.state.selectedDeviceClass.value !== config.Platform) {
                return false;
            }
            if (this.state.selectedBuckets.length > 0 && !this.state.selectedBuckets.map(item => item.value).includes(config.CurrentBucket)) {
                return false;
            }
            return true;
        }).map(config => config.OS);

        buckets = this.state.appConfigs.filter(config => {
            if (this.state.selectedAppName && this.state.selectedAppName !== config.AppName) {
                return false;
            }
            if (this.state.selectedMetric && this.state.selectedMetric.value !== config.MetricName) {
                return false;
            }
            if (latestFilter && !this.state.selectedAppVersion.map(item => item.value).includes(config.AppVersion)) {
                return false;
            }
            if (this.state.selectedDeviceClass && this.state.selectedDeviceClass.value !== config.Platform) {
                return false;
            }
            if (this.state.selectedOSVersion.length > 0 && !this.state.selectedOSVersion.map(item => item.value).includes(config.OS)) {
                return false;
            }
            return true;
        }).map(config => config.CurrentBucket);

        metrics = _.uniq(metrics);
        appVersions = _.uniq(appVersions).sort(this._versionCompare);
        osVersions = _.uniq(osVersions).sort();
        deviceClasses = _.uniq(deviceClasses);
        buckets = _.uniq(buckets).sort();

        this.setState({
            metrics: metrics,
            appVersions: appVersions,
            osVersions: osVersions,
            deviceClasses: deviceClasses,
            buckets: buckets
        });
    }

    /**
     * Compares two software version numbers (e.g. "1.7.1" or "1.2b").
     *
     * This function was born in http://stackoverflow.com/a/6832721.
     *
     * @param {string} v1 The first version to be compared.
     * @param {string} v2 The second version to be compared.
     * @returns {number|NaN} 0 if the versions are equal, -integer iff v1 < v2, +integer iff v1 > v2, NaN if either version string is in the wrong format
     *
     * @copyright by Jon Papaioannou (["john", "papaioannou"].join(".") + "@gmail.com")
     * @license This function is in the public domain. Do what you want with it, no strings attached.
     * 
     * https://gist.github.com/TheDistantSea/8021359#file-version_compare-js
     */
    _versionCompare(v1, v2) {
        let lexicographical = false,
            zeroExtend = true,
            v1parts = v1.split('.'),
            v2parts = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push('0');
            while (v2parts.length < v1parts.length) v2parts.push('0');
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (let i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) {
                return 1;
            }

            if (v1parts[i] === v2parts[i]) {
                continue;
            }
            else if (v1parts[i] < v2parts[i]) {
                return 1;
            }
            else {
                return -1;
            }
        }

        if (v1parts.length !== v2parts.length) {
            return -1;
        }

        return 0;
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this._fetchAeroInfo(this.state.aeroId);
        }
    }

    handleMetricNameChange(event) {
        const metricName = event.target.value;
        const metricNameHasValue = metricName.trim().length > 0;
        this.setState({
            metricName: metricName,
            errorMetricName: !metricNameHasValue,
            metricNameStatusMessage: !metricNameHasValue ? 'Metric name must be set and unique' : ''
        });
        if (metricNameHasValue) {
            event.persist();
        }
    }

    handleRuleEmailChange(emails) {
        this.setState({
            emailNotify: emails,
            isValidEmailSet: this._isValidEmailSet(emails)
        });
    }

    handleRuleStartDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleRuleScheduleTypeChange(scheduleType) {
        this.setState({
            ruleScheduleType: scheduleType
        });
    }

    handleRuleSchedulePeriodChange(schedulePeriod) {
        // validation for rule schedule period
        // must be numeric
        this.setState({
            errorRuleSchedulePeriod: false,
            ruleSchedulePeriodStatusMessage: ''
        });
        let value = schedulePeriod;
        let hasError = false;
        try {
            value = parseInt(schedulePeriod, 10);
            hasError = isNaN(value) || value <= 0;
        }
        catch (err) {}

        if (hasError) {
            this.setState({
                errorRuleSchedulePeriod: true,
                ruleSchedulePeriodStatusMessage: 'Must be integer > 0'
            });
        }
        this.setState({
            ruleSchedulePeriod: !hasError ? value : schedulePeriod
        });
    }

    handleMinimumDeviceCountChanged(minimumDeviceCount) {
        // validation for rule schedule period
        // must be numeric
        this.setState({
            errorMinimumDeviceCount: false,
            MinimumDeviceCountStatusMessage: ''
        });
        let value = minimumDeviceCount;
        let hasError = false;
        try {
            value = parseInt(minimumDeviceCount, 10);
            hasError = isNaN(value) || value <= 0;
        }
        catch (err) {}

        if (hasError) {
            this.setState({
                errorMinimumDeviceCount: true,
                MinimumDeviceCountStatusMessage: 'Must be integer > 0'
            });
        }
        this.setState({
            minimumDeviceCount: !hasError ? value : minimumDeviceCount
        });
    }

    handleRuleOptionChange(ruleType, option, newValue) {
        const updatedRules = this.state.rules.map(rule => {
            if (rule.ruleType === ruleType) {
                return Object.assign({}, rule, {
                    criteria: Object.assign({}, rule.criteria, {
                        [option.OptionUIField]: newValue
                    })
                });
            }
            return rule;
        });
        this.setState({
            rules: updatedRules
        });
    }

    handleRuleExtendedOptionChange(rule, optionName, extendedOption, newValue) {
        // if 'useDimensionValue' option has been changed, then null out option value
        // since we are either going from using a dimension column as value to using
        // a regular value or vice versa
        let nullifiedOptionValue = rule.criteria[optionName];
        if (extendedOption === 'useDimensionValue') {
            nullifiedOptionValue = null;
            rule.extendedOptions.breakEarly = !newValue;
        }
        const updatedRule = Object.assign({}, rule, {
            criteria: Object.assign({}, rule.criteria, {
                [optionName]: nullifiedOptionValue
            }),
            extendedOptions: Object.assign({}, rule.extendedOptions, {
                [optionName]: Object.assign({}, rule.extendedOptions[optionName], {
                    [extendedOption]: newValue
                })
            })
        });
        const updatedRules = this.state.rules.map(oldRule =>
            updatedRule.id === oldRule.id ? updatedRule : oldRule
        );
        this.setState({
            rules: updatedRules
        });
    }

    handleRuleTypeChanged(ruleType) {
        let allRuleTypes = this.state.allRuleTypes;
        allRuleTypes[ruleType].checked = !allRuleTypes[ruleType].checked;
        this.setState({
            allRuleTypes: allRuleTypes
        });
    }

    handleMonitorLatestChanged() {
        const latestObj = { value: 'Latest', label: 'Latest' };
        const appVersions = this.state.selectedAppVersion.filter(appVersion => appVersion.value !== latestObj.value);
        if (!this.state.monitorLatestAppVersion) appVersions.unshift(latestObj);
        this.setState({ monitorLatestAppVersion: !this.state.monitorLatestAppVersion, selectedAppVersion: appVersions });
    }

    handleContributorsChange(updatedContributors) {
        this.setState({
            contributors: updatedContributors
        });
    }

    _formatConfig(config) {
        return {
            AppName: config.AppName,
            AppVersion: config.AppVersion,
            CurrentBucket: config.CurrentBucket,
            OS: config.OS,
            Platform: config.Platform
        };
    }

    handleSubmit(event) {
        this.setState({
            isSubmitting: true
        });

        const appNames = [{ AppName: this.state.selectedAppName }];
        const appVersions = this.state.selectedAppVersion.map(item => ({ AppVersion: item.value }));
        const OSs = this.state.selectedOSVersion.map(item => ({ OS: item.value }));
        const platforms = [{ Platform: this.state.selectedDeviceClass.value }];
        const buckets = this.state.selectedBuckets.map(item => ({ CurrentBucket: item.value }));
        const metrics = [{ Metric: this.state.selectedMetric }];

        const allPossibleConfigs = this._allPossibleCases([appNames, appVersions, OSs, platforms, buckets, metrics]).map(this._formatConfig);

        // Creating or forking, same process to submit. Get all combinations of configs into rule filters
        let baseRules = Object.keys(this.state.allRuleTypes).filter(key => this.state.allRuleTypes[key].checked).map(ruleType => this._getBaseRuleConfig(ruleType));
        let updatedRules = baseRules.map(rule => {
            rule.emailNotify = this.state.emailNotify;
            rule.startDate = this.state.startDate;
            rule.ruleScheduleType = this.state.ruleScheduleType;
            rule.ruleSchedulePeriod = this.state.ruleSchedulePeriod;
            rule.source = source.aero;
            rule.signalColumn = this.state.selectedMetric.value;
            if (rule.criteria.MinimumDeviceCount) rule.criteria.MinimumDeviceCount = this.state.minimumDeviceCount || 25;
            return rule;
        });
        updatedRules = allPossibleConfigs.map(config => {
            return updatedRules.map(rule => {
                let ruleCopy = _.cloneDeep(rule);
                ruleCopy.filters = config;
                return ruleCopy;
            });
        });
        updatedRules = [].concat.apply([], updatedRules);
        // Editing - Compare configs (ie. filters) of old rules (fetched on load) to what is being submitted to preserve ruleId for rules that have not changed
        if (this.state.metricId && !this.props.isForking && (this.state.originalSelectedMetric.value === this.state.selectedMetric.value)) {
            // Preserve ruleId for alert history
            updatedRules = updatedRules.map(newRule => {
                const ruleIds = this.state.rules.map(oldRule => {
                    // Same config for oldRule and newRule, so preserve the ruleId for this one
                    if (this.configsMonitoringLatest.has(JSON.stringify(oldRule.filters))) {
                        oldRule.filters.AppVersion = 'Latest';
                    }
                    if (oldRule.ruleType === newRule.ruleType && this._filtersEqual(oldRule.filters, newRule.filters)) {
                        return oldRule.ruleId;
                    }
                    return -1;
                }).filter(ruleId => ruleId > -1);
                // ruleIds will be array of size 0 or 1, with the ruleId being the first and only element in the list, or no elements otherwise
                let ruleId = null;
                if (!_.isEmpty(ruleIds)) {
                    ruleId = ruleIds[0] | 0; // string to int
                }
                newRule.ruleId = ruleId;
                return newRule;
            });
        }

        const formObj = {
            metricId: this.state.metricId,
            metricName: this.state.metricName,
            inputVars: allPossibleConfigs,
            signalColumn: this.state.selectedMetric.value,
            timestampColumn: '',
            dimensionColumns: [
                { 'name': 'AppName', 'type': 'nvarchar' },
                { 'name': 'AppVersion', 'type': 'nvarchar' },
                { 'name': 'MetricName', 'type': 'nvarchar' },
                { 'name': 'OS', 'type': 'nvarchar' },
                { 'name': 'Platform', 'type': 'nvarchar' },
                { 'name': 'CurrentBucket', 'type': 'nvarchar' }
            ],
            rules: updatedRules,
            contributors: this.state.contributors.filter(alias => alias.trim().length > 0),
            aeroConfigId: this.state.aeroConfigId,
            monitorLatestAppVersion: this.state.monitorLatestAppVersion
        };
        this.props.onSubmit(formObj);
        event.preventDefault();
    }

    _filtersEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        // Must have same keys
        if (!_.isEmpty(_.xor(keys1, keys2))) {
            return false;
        }
        // And every k-v must match
        return keys1.every(key => obj1[key] === obj2[key]);
    }

    handleLoadMetricConfigData() {
        this._fetchAeroMetricConfigurations();
    }

    handleRuleTypeChecked(ruleType) {
        if (this.state.rules.some(rule => rule.ruleType === ruleType)) {
            this.handleRemoveAlert(ruleType);
        }
        else {
            this.handleAddAlert(ruleType);
        }
    }

    _getBaseRuleConfig(newRuleType) {
        const defaultOptions = this._buildDefaultOptions(this.state.ruleOptionSpecifications[newRuleType]);
        return {
            id: this.state.rules.length > 0 ? this.state.rules.slice(-1)[0].id + 1 : 0,
            ruleId: null,
            filters: [['', '=', '']],
            ruleType: newRuleType,
            criteria: defaultOptions.defaultOptions,
            extendedOptions: defaultOptions.defaultExtendedOptions,
            isValidEmailSet: true,
            emailNotify: [''],
        };
    }

    handleAddAlert(newRuleType, rule) {
        rule = rule || this._getBaseRuleConfig(newRuleType);
        const updatedRules = this.state.rules.concat(rule);
        this.setState({
            rules: updatedRules
        });
    }

    handleRemoveAlert(removeRuleType) {
        const updatedRules = this.state.rules.filter(r => r.ruleType !== removeRuleType);
        this.setState({
            rules: updatedRules
        });
    }

    componentDidMount() {
        this._fetchAeroMetricConfigurations();
        this._fetchRuleOptionSpecifications();
        this._fetchRuleScheduleTypes();

        const metricId = this.props.metricId;
        if (metricId) {
            this._fetchAeroMetricPullerConfig(metricId);
            this._fetchRules(this.props.metricId);
        }
    }

    _fetchAeroMetricPullerConfig(metricId) {
        this.setState({
            loadingAeroMetricConfig: true
        });
        Fetch(`${API_BASE}/metricPuller/puller/?metricId=${metricId}&source=${source.aero}`, {
            credentials: 'include'
        }).then(aeroConfigs => {
            const contributors = aeroConfigs.length > 0 ? aeroConfigs[0].Contributors : [];
            let metrics = [];
            let appVersions = [];
            let deviceClasses = [];
            let osVersions = [];
            let buckets = [];
            let monitorLatest = false;

            aeroConfigs.forEach(aeroConfig => {
                Object.keys(aeroConfig.InputVars).forEach(attr => {
                    if (attr === 'AppName') this.setState({ selectedAppName: aeroConfig.InputVars[attr] });
                    else if (attr === 'AppVersion' && !aeroConfig.MonitorLatestAppVersion) appVersions.push(aeroConfig.InputVars[attr]);
                    else if (attr === 'OS') osVersions.push(aeroConfig.InputVars[attr]);
                    else if (attr === 'Platform') deviceClasses.push(aeroConfig.InputVars[attr]);
                    else if (attr === 'CurrentBucket') buckets.push(aeroConfig.InputVars[attr]);
                });
                if (aeroConfig.MonitorLatestAppVersion) {
                    this.configsMonitoringLatest.add(JSON.stringify(aeroConfig.InputVars));
                    monitorLatest = true;
                }
                metrics.push(aeroConfig.SignalColumn);
            });

            metrics = _.uniq(metrics).map(item => ({ value: item, label: item }))[0];
            appVersions = _.uniq(appVersions).map(item => ({ value: item, label: item }));
            deviceClasses = _.uniq(deviceClasses).map(item => ({ value: item, label: item }))[0];
            osVersions = _.uniq(osVersions).map(item => ({ value: item, label: item }));
            buckets = _.uniq(buckets).map(item => ({ value: item, label: item }));

            let metricName = aeroConfigs[0].MetricName;
            if (this.props.isForking) {
                metricName = '';
                aeroConfigs[0].Contributors.push(aeroConfigs[0].Author);
            }

            this.setState({
                author: aeroConfigs[0].Author,
                metricId: this.props.metricId,
                metricName: metricName,
                contributors: contributors,
                loadingAeroMetricConfig: false,
                errorMetricName: metricName.trim().length === 0,
                originalSelectedMetric: metrics,
                selectedMetric: metrics,
                selectedAppVersion: appVersions,
                selectedDeviceClass: deviceClasses,
                selectedBuckets: buckets,
                selectedOSVersion: osVersions,
            }, () => {
                if (monitorLatest) {
                    this.handleMonitorLatestChanged();
                }
            });
        }).catch(err => {
            this.setState({
                loadingAeroMetricConfig: false,
                errorAeroMetricConfig: true,
                errorAeroMetricConfigStatusMessage: err,
            });
        });
    }

    _fetchRuleOptionSpecifications() {
        this.setState({
            loadingRuleOptions: true
        });
        return Fetch(`${API_BASE}/rules/uiOptionSpecifications`, { credentials: 'include' }).then(specs => {
            const updatedRules = this.state.rules.map(rule => {
                const ruleSpec = specs[rule.ruleType];

                // build the default extended options for a rule from the spec to use in case they have not
                // already been set. use existing extendedOptions if they exist.
                const defaultOptions = this._buildDefaultOptions(ruleSpec);
                return Object.assign({}, rule, {
                    extendedOptions: Object.assign({}, defaultOptions.defaultExtendedOptions, rule.extendedOptions),
                    criteria: Object.assign({}, defaultOptions.defaultOptions, rule.criteria)
                });
            });
            Object.keys(specs).forEach(key => {
                if (!Object.keys(this.state.allRuleTypes).includes(key)) delete specs[key];
            });
            this.setState({
                ruleOptionSpecifications: specs,
                rules: updatedRules,
            });
        }).catch(err => {
            this.setState({
                loadingRuleOptions: false,
                errorRuleOptions: true,
                errorRuleOptionsStatusMessage: err,
            });
            // TODO: enhance error display
        });
    }

    _fetchRuleScheduleTypes() {
        return Fetch(`${API_BASE}/rules/possibleScheduleTypes`).then(scheduleTypes => {
            this.setState({
                possibleRuleScheduleTypes: scheduleTypes
            });
        });
    }

    _fetchRules(metricId) {
        if (!metricId) {
            return Promise.reject();
        }
        this.setState({ loadingRules: true });
        return Fetch(`${API_BASE}/rules?metricId=${metricId}`, {
            credentials: 'include'
        }).then(data => {
            const rules = data.rules;

            const ruleSchedulePeriod = rules[0].ruleSchedulePeriod || 12;
            const ruleScheduleType = rules[0].ruleScheduleType || 1;
            const startDate = rules[0].startDate || new Date().toUTCString();
            const emailNotify = rules[0].emailNotify;

            let minimumDeviceCount = 25;

            let existingRuleTypes = new Set();
            rules.forEach(rule => {
                rule.isValidEmailSet = this._isValidEmailSet(rule.emailNotify);
                // Some rules already have nulls for scheduling since it wasn't checked before. If they have non-null values use those, otherwise set defaults to start now, running every 12 hours.
                rule.ruleSchedulePeriod = rule.ruleSchedulePeriod || 12;
                rule.ruleScheduleType = rule.ruleScheduleType || 1;
                rule.startDate = rule.startDate || new Date().toUTCString();
                rule.ruleId = parseInt(rule.ruleId, 10);
                rule.filters = {
                    AppName: _.find(rule.filters, (obj) => obj.name === 'AppName').value,
                    AppVersion: _.find(rule.filters, (obj) => obj.name === 'AppVersion').value,
                    CurrentBucket: _.find(rule.filters, (obj) => obj.name === 'CurrentBucket').value,
                    OS: _.find(rule.filters, (obj) => obj.name === 'OS').value,
                    Platform: _.find(rule.filters, (obj) => obj.name === 'Platform').value
                };
                minimumDeviceCount = rule.criteria.MinimumDeviceCount || minimumDeviceCount;
                existingRuleTypes.add(rule.ruleType);
            });
            let allRuleTypes = this.state.allRuleTypes;
            Object.keys(allRuleTypes).forEach(ruleType => {
                allRuleTypes[ruleType].checked = existingRuleTypes.has(ruleType);
            });

            this.setState({
                rules: rules.map((rule, index) => Object.assign({
                    // create id to keep track of newly-created rules
                    id: index
                },
                rule,
                {
                    criteria: Object.assign({}, this._buildDefaultOptions(this.state.ruleOptionSpecifications[rule.ruleType]).defaultOptions, rule.criteria),
                    extendedOptions: Object.assign({}, this._buildDefaultOptions(this.state.ruleOptionSpecifications[rule.ruleType]).defaultExtendedOptions, rule.extendedOptions)
                })),
                ruleSchedulePeriod: ruleSchedulePeriod,
                ruleScheduleType: ruleScheduleType,
                startDate: startDate,
                emailNotify: emailNotify,
                allRuleTypes: allRuleTypes,
                minimumDeviceCount: minimumDeviceCount,
                showMinimumDeviceCountEntry: true,

                loadingRules: false
            });

            if (_.isFunction(this.props.onRulesFetched)) {
                this.props.onRulesFetched(rules);
            }
        }).catch(err => {
            this.setState({
                loadingRules: false,
                errorRules: true,
                errorRulesMessage: err
            });
        });
    }

    _isValidEmailSet(emails) {
        return emails.every(email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) || (emails.length === 1 && emails[0] === '');
    }

    _buildDefaultOptions(ruleSpec) {
        if (!ruleSpec) {
            return {
                defaultOptions: undefined,
                defaultExtendedOptions: undefined
            };
        }
        return ruleSpec.reduce((returnObj, spec) => {
            // create default top-level options from the given ruleOptionSpecification
            returnObj.defaultOptions[spec.OptionUIField] = spec.DefaultValue || '';

            // create default extended options on the top-level options from the given ruleOptionSpecification
            returnObj.defaultExtendedOptions[spec.OptionUIField] = spec.ExtendedOptions ? spec.ExtendedOptions.reduce((obj, extendedOption) => {
                obj[extendedOption.OptionUIField] = extendedOption.DefaultValue || '';
                return obj;
            }, {}) : undefined;
            return returnObj;
        }, {
            defaultOptions: {},
            defaultExtendedOptions: {}
        });
    }

    _fetchAeroMetricConfigurations() {
        this.setState({
            loadingMetricConfigInfo: true,
            errorMetricConfigInfo: false,
            metricConfigInfoStatusMessage: ''
        });

        return Fetch(`${API_BASE}/metrics/aeroConfigs/`, {
            credentials: 'include'
        }).then(configs => {
            let appConfigs = [];
            let appNames = [];
            configs.forEach(config => {
                if (!appNames.includes(config.AppName)) {
                    appNames.push(config.AppName);
                }
                appConfigs.push(config);
                if (!(config.AppName in this.retailAppVersions)) {
                    this.retailAppVersions[config.AppName] = new Set();
                }
                if (config.CurrentBucket === 'Retail' && !this.retailAppVersions[config.AppName].has(config.AppVersion)) {
                    this.retailAppVersions[config.AppName].add(config.AppVersion);
                }
            });
            this.setState({
                appConfigs: configs,
                appNames: appNames,
                loadingMetricConfigInfo: false,
            }, () => this._filterAppParameters());
        }).catch(err => {
            this.setState({
                loadingMetricConfigInfo: false,
                errorMetricConfigInfo: true,
                metricConfigInfoStatusMessage: err
            });
        });
    }

    _filterOption(option, filter) {
        filter = filter.toLowerCase();
        let value = option.value.toLowerCase();
        let label = option.label.toLowerCase();
        let searchable = option.searchable;
        return value.includes(filter) || label.includes(filter) || searchable.some(keyword => keyword.toLowerCase().includes(filter));
    }

    _sqlServerTypeIsNumeric(sqlType) {
        return sqlType === 'bigint' || sqlType === 'int' || sqlType === 'float' || sqlType === 'numeric' || sqlType === 'decimal' || sqlType === 'real';
    }

    // adopted from stackoverflow: https://stackoverflow.com/questions/4331092/finding-all-combinations-of-javascript-array-values
    _allPossibleCases(arr) {
        if (arr.length === 1) {
            return arr[0];
        }
        else {
            var result = [];
            var allCasesOfRest = this._allPossibleCases(arr.slice(1));
            for (var i = 0; i < allCasesOfRest.length; i++) {
                for (var j = 0; j < arr[0].length; j++) {
                    result.push(Object.assign({}, arr[0][j], allCasesOfRest[i]));
                }
            }
            return result;
        }
    }

    render() {
        const loadingData = this.state.loadingRules || this.state.loadingMetricConfigInfo || this.state.loadingAeroMetricConfig;
        const loadingDataClass = loadingData ? 'disabled' : '';
        const loadingSubmit = this.props.loadingSubmit;
        const hasSelectedAppNameClass = !this.state.selectedAppName ? 'disabled' : '';
        const scheduleTypeMap = this.state.possibleRuleScheduleTypes || {};
        const errorNoRulesSelected = Object.keys(this.state.allRuleTypes).filter(key => this.state.allRuleTypes[key].checked).length === 0;
        const emailSetValid = this.state.isValidEmailSet;
        const emailInputClass = emailSetValid ? 'input-good' : 'input-error';
        const errorInScheduling = !this.state.ruleSchedulePeriod || !this.state.ruleScheduleType || !this.state.startDate;
        const errorInAppParameters = _.isEmpty(this.state.selectedAppName) || (_.isEmpty(this.state.selectedAppVersion) && !this.state.monitorLatestAppVersion)
            || _.isEmpty(this.state.selectedDeviceClass) || _.isEmpty(this.state.selectedMetric) || _.isEmpty(this.state.selectedOSVersion) || _.isEmpty(this.state.selectedBuckets);
        const errorInForm = this.state.errorMetricName || this.state.errorRuleSchedulePeriod || errorInScheduling || errorInAppParameters || errorNoRulesSelected;
        return (
            <div className="AeroMonitorForm">
                <div className="rule-form-header">
                    <h1>
                        { this.props.title || 'Monitor Aero Apps' }
                    </h1>
                    <p className="subtext">
                        {this.createMonitorDescription}
                    </p>
                </div>

                <div className="AeroMonitorForm container-fluid" >

                    <div className={'input-group'}>
                        <div className="alert-section-header">
                            <h4>Monitor Name</h4>
                            <p className="subtext">
                            </p>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-11">
                                <input type="text" placeholder={this.metricNamePlaceholder} className={classnames('form-control', { 'error': this.state.errorMetricName })} name="metricName" value={this.state.metricName} onChange={this.handleMetricNameChange}/>
                                {
                                    this.state.metricNameStatusMessage &&
                                        <span className={classnames({ 'error-status': this.state.errorMetricName })}>{ this.state.metricNameStatusMessage }</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={'input-group'}>
                        <div className="alert-section-header">
                            <h4>Choose App Parameters</h4>
                            <p className="subtext">
                            </p>
                            <Loading
                                isLoading={loadingData}
                                loadingClassName='loading'
                            >
                            </Loading>
                        </div>

                        <div className={'form-group row ' + loadingDataClass}>
                            <label htmlFor="aeroId" className={'col-sm-2 control-label'}>App Name</label>
                            <div className="col-sm-9">
                                <Select
                                    name={'metric-select'}
                                    placeholder={'Select app'}
                                    value={this.state.selectedAppName}
                                    onChange={this.handleAppNameChange}
                                    options={this.state.appNames.map(item => ({ value: item, label: item }))}
                                />
                            </div>
                        </div>

                        <div className={'form-group row ' + loadingDataClass}>
                            <label htmlFor="aeroId" className={'col-sm-2 control-label'}>Metric</label>
                            <div className="col-sm-9">
                                <Select
                                    name={'metric-select'}
                                    placeholder={'Select metric values'}
                                    filterOption={this._filterOption}
                                    value={this.state.selectedMetric}
                                    onChange={this.handleMetricChange}
                                    options={this.state.metrics.map(item => ({ value: item, label: item, searchable: this.searchableMetric[item] }))}
                                />
                            </div>
                        </div>

                        <div className={'form-group row ' + hasSelectedAppNameClass}>
                            <label htmlFor="aeroId" className="col-sm-2 control-label">Rings</label>
                            <div className="col-sm-9">
                                <Select
                                    name={'rings-select'}
                                    placeholder={'Select rings'}
                                    multi={true}
                                    closeOnSelect={false}
                                    value={this.state.selectedBuckets}
                                    onChange={this.handleBucketsChange}
                                    options={this.state.buckets.map(item => ({ value: item, label: item.replace('App ', '') }))}
                                />
                            </div>
                        </div>

                        <div className={'form-group row ' + hasSelectedAppNameClass}>
                            <label htmlFor="aeroId" className="col-sm-2 control-label">App Versions</label>
                            <div className="col-sm-9">
                                <Select
                                    name={'app-version-select'}
                                    placeholder={'Select app versions'}
                                    multi={true}
                                    closeOnSelect={false}
                                    value={this.state.selectedAppVersion}
                                    onChange={this.handleAppVersionChange}
                                    options={this.state.appVersions.map(item => {
                                        if (this.state.selectedAppName && this.retailAppVersions[this.state.selectedAppName].has(item)) return { value: item, label: `${item} - Retail` };
                                        return { value: item, label: item };
                                    })}
                                />
                                <div className={'use-latest'}>
                                    <input
                                        value={this.state.monitorLatestAppVersion}
                                        checked={this.state.monitorLatestAppVersion}
                                        onChange={() => this.handleMonitorLatestChanged()}
                                        label={'Always use latest'}
                                        type="checkbox"
                                        className={'spec-option'}
                                    />
                                    <h5 className={'spec-option'}>{this.monitorLatestTitleString}</h5>
                                    <p className={'monitor-latest-info'}>{this.monitorLatestInfoString}</p>
                                </div>
                            </div>
                        </div>

                        <div className={'form-group row ' + hasSelectedAppNameClass}>
                            <label htmlFor="aeroId" className="col-sm-2 control-label">OS Versions</label>
                            <div className="col-sm-9">
                                <Select
                                    name={'os-version-select'}
                                    placeholder={'Select OS versions'}
                                    multi={true}
                                    closeOnSelect={false}
                                    value={this.state.selectedOSVersion}
                                    onChange={this.handleOSVersionChange}
                                    options={this.state.osVersions.map(item => ({ value: item, label: item }))}
                                />
                            </div>
                        </div>

                        <div className={'form-group row ' + hasSelectedAppNameClass}>
                            <label htmlFor="aeroId" className="col-sm-2 control-label">Device Class</label>
                            <div className="col-sm-9">
                                <Select
                                    name={'device-class-select'}
                                    placeholder={'Select platform'}
                                    value={this.state.selectedDeviceClass}
                                    onChange={this.handleDeviceClassChange}
                                    options={this.state.deviceClasses.map(item => ({ value: item, label: item }))}
                                />
                            </div>
                        </div>

                    </div>

                    <div className={'input-group'}>
                        <div className="alert-section-header">
                            <h4>Alert me when</h4>
                            <p className="subtext">
                            </p>
                        </div>
                        <div className={'form-group row ' + loadingDataClass}>
                            { Object.keys(this.state.allRuleTypes).map(key => (
                                <div key={key} className={'col-sm-9'}>
                                    <label>
                                        <input
                                            name={key}
                                            type={'checkbox'}
                                            checked={this.state.allRuleTypes[key].checked}
                                            onChange={() => this.handleRuleTypeChanged(key)}
                                        />
                                        { this.state.allRuleTypes[key].description }
                                    </label>
                                </div>
                            )) }
                        </div>

                    </div>

                    <div className={'input-group'}>
                        <div className="alert-section-header">
                            <h4>Start monitoring when</h4>
                            <p className="subtext">
                            </p>
                        </div>

                        <div className={'form-group row col-sm-9 ' + loadingDataClass}>
                            <label>
                                <input
                                    type={'checkbox'}
                                    checked={this.state.showMinimumDeviceCountEntry}
                                    onChange={() => this.handleShowMinimumDeviceCountChanged()}
                                />
                                { this.minDeviceCountTitleString }
                                <p className={'monitor-latest-info'}><i>{this.minDeviceCountInfoString}</i></p>
                            </label>
                            <input
                                type="number"
                                placeholder={this.minDeviceCountPlaceholder}
                                className={classnames('form-control', 'minDeviceCountEntry', 'col-sm-2', { 'hidden': !this.state.showMinimumDeviceCountEntry })}
                                name="minDeviceCount"
                                value={this.state.minimumDeviceCount}
                                onChange={(e) => this.handleMinimumDeviceCountChanged(e.target.value)}
                            />
                            {
                                this.state.MinimumDeviceCountStatusMessage &&
                                    <div>
                                        <span className={classnames({ 'error': this.state.errorMinimumDeviceCount })}>{ this.state.MinimumDeviceCountStatusMessage }</span>
                                    </div>
                            }
                        </div>

                    </div>

                    <div className={'input-group'}>
                        <div className="rule-email row">
                            <label className="col-sm-2" htmlFor="email">
                                Emails to Notify
                                { !emailSetValid && <span className='error-string'><br />{'Invalid format'}</span> }
                            </label>
                            <div className="col-sm-9 email-input-div">
                                <TagsInput
                                    value={this.state.emailNotify}
                                    onChange={this.handleRuleEmailChange}
                                    addKeys={[9, 13, 32, 186, 188]} // Tab, Enter, Space, Semicolon, Comma
                                    addOnBlur={true}
                                    inputProps={ { className: 'react-tagsinput-input ' + emailInputClass, placeholder: 'alias@microsoft.com' } }
                                />
                            </div>
                        </div>
                    
                        <div className="rule-start-date row">
                            <label className="col-sm-2" htmlFor="start-date">Start date</label>
                            <div className="col-sm-3">
                                <DatePicker
                                    selected={moment(this.state.startDate)}
                                    onChange={this.handleRuleStartDateChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="LLL"
                                />
                            </div>
                            <div className="col-sm-3">
                                <div className="row">
                                    <label className="col-sm-6 schedule-period-control" htmlFor="schedule-period">Every</label>
                                    <div className="col-sm-6">
                                        <input
                                            id="schedule-period"
                                            type="number"
                                            className={classnames('form-control schedule-period-control', { 'error': this.state.errorRuleSchedulePeriod })}
                                            value={this.state.ruleSchedulePeriod}
                                            onChange={(e) => this.handleRuleSchedulePeriodChange(e.target.value)}
                                        >
                                        </input>
                                        {
                                            this.state.ruleSchedulePeriodStatusMessage &&
                                                <div>
                                                    <span className={classnames({ 'error': this.state.errorRuleSchedulePeriod })}>{ this.state.ruleSchedulePeriodStatusMessage }</span>
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-2">
                                <Dropdown
                                    id="ruleScheduleType"
                                    possibleValues={Object.keys(scheduleTypeMap).map((scheduleTypeName) => ({ ID: this.state.possibleRuleScheduleTypes[scheduleTypeName], Name: scheduleTypeName }))}
                                    selectedValue={this.state.ruleScheduleType}
                                    onChange={e => this.handleRuleScheduleTypeChange(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div className={'input-group col-sm-11'}>
                        <div className="alert-section-header">
                            <h4>Contributors</h4>
                        </div>
                        <TagsInput
                            value={this.state.contributors}
                            onChange={this.handleContributorsChange}
                            addKeys={[9, 13, 32, 186, 188]} // Tab, Enter, Space, Semicolon, Comma
                            addOnBlur={true}
                            inputProps={ { className: 'react-tagsinput-input', placeholder: 'Add contributor aliases' } }
                        />
                        { this.state.author && <p><b>Author:</b> {this.state.author}</p> }
                    </div>

                    <Loading
                        isLoading={loadingSubmit}
                        loadingClassName='loading'
                    >
                        <button disabled={loadingSubmit || errorInForm} className={classnames('btn btn-default', { 'error': errorInForm })} onClick={this.handleSubmit}>Submit</button>
                    </Loading>
                </div>
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default AeroMonitorForm;
