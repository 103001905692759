import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { source } from '../../../scripts/constants';
import './style.css';

export default class MonitorItem extends PureComponent {
    handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this monitor?')) {
            this.props.handleDeleteConfirm();
        }
        else {
            this.props.handleDeleteCancel();
        }
    };
    _createFriendlyCriteria(criteria) {
        // criteria passed in is object of key value pairs
        return (
            <div className="rule-criteria-container">
                {
                    Object.keys(criteria).filter(key => key.toLowerCase() !== 'breakearly').map(key => (
                        <div key={key} className="rule-criteria">
                            { key }: <span className="strong">{ `${criteria[key]}` }</span>
                        </div>
                    ))
                }
            </div>
        );
    }
    _getBasePath(dataSource) {
        switch (dataSource) {
        case source.charts: return '/monitor/';
        case source.aero: return '/next/';
        default: return '';
        }
    }

    _getSource(monitor) {
        switch (monitor.dataSource) {
        case source.charts: return <a href={`https://charts/#/edit/${monitor.sourcePath}`}>charts</a>;
        case source.aero: return 'aero';
        default: return 'unknown';
        }
    }

    render() {
        const monitor = this.props.monitor;
        const devToolsClass = this.props.showDevTools ? '' : 'hideDevTools';
        const basePath = this._getBasePath(monitor.dataSource);
        const dataSource = this._getSource(monitor);
        return (
            <tr className="MonitorItem" align="left">
                <td>
                    <Link to={{ pathname: `${basePath}view/${monitor.metricId}` }}>
                        {monitor.metricName}
                        {monitor.newRecords && monitor.newRecords > 0 ? <span className="badge badge-warning">{monitor.newRecords}</span> : ''}
                    </Link>
                </td>
                <td>{monitor.signal}</td>
                <td>{dataSource}</td>
                <td>
                    <Link to={{ pathname: `${basePath}fork/${monitor.metricId}` }}>Fork</Link>
                </td>
                <td>
                    { monitor.canEdit &&
                        <Link to={{ pathname: `${basePath}edit/${monitor.metricId}` }}>Edit</Link>
                    }
                </td>
                <td>
                    { monitor.canDelete &&
                        <a onClick={() => this.handleDelete()}>Delete</a>
                    }
                </td>
                <td className={devToolsClass}><a onClick={() => this.props.onGetData()}>Get Data</a></td>
                <td className={devToolsClass}><a onClick={() => this.props.onRunRules()}>Run Rule</a></td>
            </tr>
        );
    }
}