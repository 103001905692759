import React, { Component } from 'react';
import MonitorList from './MonitorList';

import './style.css';

export default class MonitorListPage extends Component {
    render() {
        // location state set when being redirected from a successful create metric
        const createdMetric = this.props.history.location.state && this.props.history.location.state.createdMetric;
        const editedMetric = this.props.history.location.state && this.props.history.location.state.editedMetric;
        const redirectedFromCreate = createdMetric !== undefined;
        const redirectedFromEdit = editedMetric !== undefined;
        return (
            <div className="MonitorListPage wrapper">
                <h1>Monitors</h1>
                <MonitorList
                    redirectedFromEdit={redirectedFromEdit}
                    redirectedFromCreate={redirectedFromCreate}
                    createdMetric={createdMetric}
                    from={this.props.history.action}
                />
            </div>
        );
    }

}